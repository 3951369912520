import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { Chip, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DWebContent,
} from "../model";
import { ContextService } from "../context/context-service";
import IconCard from "../components/card/icon-card";
import { BSGAPI } from "../auth/bsg-api";
import HubIcon from '@mui/icons-material/Hub';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ThemeCard from "../components/card/theme-card";

const AcademyHome = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const navigate = useNavigate();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  let [loading, setLoading] = useState(true);


  useEffect(() => {
    //document.title = "BSG Partner:Home";

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        navigate("/sign-in");
      }
    });

    BSGAPI.getWebContent("academy").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

<ThemeCard
        backgroundImg="https://my-bsg-asset.s3.amazonaws.com/program/career_coaching.jpg"
        title=""
        description=""
        minHeight={500}
        opacity={0.6}
      >      
        <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1 className="primary-light">{webContent.h1?.title} </h1>

          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2 className="primary-light">{child.content}</h2>
              </Grid>
            );
          })}
        </Grid></Container>
</ThemeCard>


<div className="section-white">
      <Container maxWidth="lg">

        <Grid
            container
            spacing={8}
            rowSpacing={8}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >

            <Grid container item xs={12} sm={6} lg={4} justifyContent="center">
            <IconCard
                icon={
                  <VideoFileIcon
                    color="secondary"
                    style={{ fontSize: 64 }}
                  />
                }
                title="Training Materials"
                description="Training videos, PPT, etc. "
                onClick={() => {
                  navigate('/academy/content'); 

                  }}
                onClickText="View"
              ></IconCard>
          </Grid>

          <Grid container item xs={12} sm={6} lg={4} justifyContent="center">
            <IconCard
                icon={
                  <HubIcon
                    color="secondary"
                    style={{ fontSize: 64 }}
                  />
                }
                title="BSG Network"
                description="Search people and companies in the BSG network"
                onClick={() => {
                  navigate('/academy/network'); 

                  }}
                onClickText="View"
              ></IconCard>
          </Grid>

        </Grid>
  </Container>
  </div>




    </div>
  );
};

export default AcademyHome;

import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Box, Container, Grid, IconButton } from "@mui/material";

const DebugCard = (props: React.PropsWithChildren<ICard>) => {
  let navigate = useNavigate();

  return (
    <Card className="debug-card">
      <div className="debug-top">
        <Box>{props.icon}</Box>
      </div>
      <div className="debug-title">
        <Box>
          <h2>{props.title}</h2>
        </Box>
      </div>
      <Box flexGrow={1}>
        <div
          className="debug-content"
          style={{
            minHeight: `${props.minHeight}px`,
            height: "auto",
          }}
        >
          <div>{props.description}</div>

          <div>{props.children}</div>
        </div>
      </Box>
      <Box alignItems="flex-end">
        <div className="debug-bottom">
          <CardActions>
            <div className="action-button">
              {props.route ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  startIcon={<ArrowForwardIosOutlinedIcon />}
                  onClick={() => {
                    navigate(props.route as string);
                  }}
                >
                  {props.routeText}
                </Button>
              ) : (
                ""
              )}

              {props.onClick ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    props.onClick();
                  }}
                >
                  {props.onClickText}
                </Button>
              ) : (
                ""
              )}

              {props.linkUrl ? (
                <a href={props.linkUrl} target="_blank">
                  {props.linkText}
                </a>
              ) : (
                ""
              )}
            </div>
          </CardActions>
        </div>
      </Box>
    </Card>
  );
};

export default DebugCard;

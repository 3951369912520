import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import "./style.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { BSGAPI2 } from "../../auth/bsg-api2";
import { DVerification } from "../../model";

export default function SMSVerifyer(props: any) {
  const onCancel = props.onCancel;
  const onConfirm = props.onConfirm;

  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);

  const [verification, setVerification] = useState<DVerification>(
    props.verification as DVerification
  );

  const [process, setProcess] = useState({
    step: 0,
    message:
      "Your verification code is either not existing or has expired. For security reason, your posting requires a quick SMS\
                  verification. Your phone number will be only used for security verification purpose.",
  });
  // 0: request code, 1: display verification code

  function cancelForm() {
    setProcess({ step: 0, message: "cancelled" });
    onCancel();
  }

  function submitCode() {
    // check to see if the code is valid. If yes then proceed.
    onConfirm(verification.phone, code);
  }

  function submitPhone() {
    setLoading(true);
    BSGAPI2.getSMSVerificationCode(verification).then((json) => {
      //console.log("status: " + json.status);
      //console.log("data: " + JSON.stringify(json.data));
      if (json.status == "ok") {
        //console.log("Complete requesting code");
        setProcess({
          step: 1,
          message: "Pease check your phone for verification code. ",
        });
      } else {
        // code not generated successfully
        //console.log("need your phone for verification code");
        setProcess({ step: 0, message: "Sorry, please try again later. " }); // error: back to requst code step. TODO: display some message
      }
      setLoading(false);
    });
  }
  return (
    <div className="sms-verifier-wrapper" >
      <Container maxWidth="md">
        <div className="sms-verifier">
          <Grid
            container
            spacing={3}
            rowSpacing={6}
            style={{ justifyContent: "center" }}
          >
            {process.step == 0 ? (
              <>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <h2>Verification Required</h2>
                  <div className="content">{process.message}</div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    disabled={loading}
                    value={verification.phone}
                    onChange={(phone) =>
                      setVerification({ ...verification, phone: phone as any })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  {loading ? (
                    <CircularProgress color="secondary" sx={{ fontSize: 80 }} />
                  ) : (
                    <></>
                  )}
                  <p></p>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitPhone}
                    disabled={
                      verification.phone == undefined ||
                      verification.phone!.length < 7 ||
                      loading
                    }
                  >
                    Verify
                  </Button>
                  <Button variant="contained" color="info" onClick={cancelForm}>
                    Cancel
                  </Button>
                </Grid>
              </>
            ) : (
              ""
            )}

            {process.step == 1 ? (
              <>
                <Grid item xs={12}>
                  {process.message}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    variant="outlined"
                    color="secondary"
                    id="code"
                    label="Code"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitCode}
                    disabled={code == undefined || code!.length < 5}
                  >
                    Submit
                  </Button>
                  <Button variant="contained" color="info" onClick={cancelForm}>
                    Cancel
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </div>
      </Container>
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Button, Container, Fade, Grid, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DNameValue, DProfile, DRelationship, DUser, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import IconCard from "../components/card/icon-card";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import NameValueCard from "../components/card/name-value-card";
import BTextField from "../components/form/text-field";
import BSelectList from "../components/form/select-list";
import { BSGAPI } from "../auth/bsg-api";
import { BSGAPI2 } from "../auth/bsg-api2";
import BFormInput from "../components/form/form-input";
import Loading from "../components/loading/loading";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import HubIcon from '@mui/icons-material/Hub';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { AdminAPI2 } from "../auth/admin-api2";
import RelationshipCard from "../components/card/relationship-card";

const AdminUserEdit = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const {email } = useParams(); 

  const [webContent, setWebContent] = useState({} as DWebContent);
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [gdrive, setGdrive] = useState("");
  //let [profile, setProfile] = useState({} as DProfile);
  const [profile, setProfile] = useState({} as any);

  const [contactCardDisabled, setContactCardDisabled] = useState(true);

  const [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

    // raw data nodes and relationships contain more business data
    const [nodes, setNodes] = useState<any[]>([])
    const [relationships, setRelationships] = useState<any[]>([]); 
  
    const addRelationship = () => {
      //const newNodes = [...nodes, { id: nodes.length as  }]
      //setNodes(newNodes)
      setRelationships(relationships.concat({fromNode: {}, toNode: {}, type: "", value: {description: ""}} as DRelationship)); 
    }
    
  const updateProfile = () => {

    setLoading(true);
    AdminAPI2.adminUpdateProfile(
      context.user.cognitoCredentials,
      profile
    ).then((json) => {
      //console.log(json);
      //setData(data);  
      if(json.status == 'ok') {
        //console.log("OK ..."); 
        //setObjects(json.data);  // for array type testing
        //setData(json.data); // for json type testing
        setLoading(false); 
        setContactCardDisabled(true); 
      } else {
        //console.log("BAD ..." + json.status); 
        setError(json.status);
        setShowError(true); 
      }
    });
  };

  const loadNetwork = (id: string | null) => { 
    setLoading(true);
    AdminAPI2.adminGetNetwork(
      context.user.cognitoCredentials,
      id, 
      null
    ).then((json) => {

      //console.log(JSON.stringify(json));
      //{"data":null,"status":"unauthorized"}

      if(json.status != 'ok') {
        //console.log("BAD ..." + json.status); 
        //return;
        history("/home");
      } else {
        //console.log("OK ...: " );

      setNodes(nodes.concat(json.data["raw"]["nodes"]));
      setRelationships(relationships.concat(json.data["raw"]["relationships"])); 
      setLoading(false);

    }

    }).catch((err: any)=> {
        //console.log(err);
    });
  }

  const getNode = (id: string) => {
    const theNode: any = nodes.find((n) => n.id == id);
    return theNode; 
  }


  useEffect(() => {
    document.title = "BSG Partner:My Profile";

    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        // for testing:
        //getMyProfile();

        // just for testing:
        //getMyAttributes();

        //setGdrive(context.user.person!.gdrive as string);

        setLoading(true);
        AdminAPI2.adminGetProfile(context.user.cognitoCredentials, email as string)
        .then((json) => {
          if(json.status == "ok") {
            //console.log("JSON: " + JSON.stringify(json)); 

            if(json.data != null) {     
              setProfile(json.data as any);
              loadNetwork(json.data.id);
            }
            //setLoading(false);

          } 
          else {
            //console.log("Error: " + JSON.stringify(json)); 
          
          
          }
        }); 

        forceUpdate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/admin/user-edit`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    BSGAPI.getWebContent("profile").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>


    
    <div>
    {loading ? (
            <div>
              <Loading show={loading} />
              
            </div>
          ) : (


      <div className="section-white">
<Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="center"
              >
                {showError && (
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                      setShowError(false);
                    }}
                    severity="error"
                  >
                    {error}
                  </MuiAlert>
                )}
              </Grid>
      <Container maxWidth="lg">

          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid
                container
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            justifyContent="center"
            alignContent="center"
          >

      <NameValueCard
              key="info-contact"
              icon={
                <ManageAccountsIcon color="info" style={{ fontSize: 64 }} />
              }
              title="User Profile"
              description=""
              onClick={() => {
                updateProfile();
                setContactCardDisabled(true);
              }}
              onClickText="Save"
              onClickDisabled={contactCardDisabled}
            >
          { Object.keys(profile).map(
            (key: any) => 
            
              { 
                return (
                <BFormInput
                label={key}
                editMode={false}
                name={key}
                value={ profile[key]}
                onChange={(value: any) => {
                  //console.log("onChange is called ...: " + key)
                  profile[key] = value; 
                  setProfile({...profile, [key]: value}); 

                  setContactCardDisabled(false); 
              }}></BFormInput>);
            }
          )}
          </NameValueCard>

          </Grid>
          </Grid>
      </Container>


      <div>

<Grid
          container
          spacing={8}
          rowSpacing={8}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          alignContent="center"
        >


{relationships.map((child) => {
          return (

          <Grid container item xs={12} lg={12} justifyContent="center">
            <RelationshipCard relationship={
              {
                "fromNode": getNode(child["from"]),
                "toNode": getNode(child["to"]), 
                "type": child["type"],
                "value": child["value"]
              }
            } />
            </Grid>
          );
        })}
</Grid>

</div>


<div>
  <Button variant="outlined" onClick={()=>{ addRelationship(); }}>Add New Relationship</Button>

</div>

    </div>





          )}
    </div>
    
    </div>
  );
};

export default AdminUserEdit;

import { Card, CircularProgress, Container, Grid } from "@mui/material";
import styles from "./layout.module.css";
import { useEffect, useState } from "react";
import { DCartItem, DChoiceOption, DChoiceOptions, DProduct } from "./model";
import ThemeCard from "../components/card/theme-card";
import ProductCard from "./components/product-card";
import Cart from "./components/cart";
import { BatteryUnknownOutlined } from "@mui/icons-material";
import { DWZStore } from "./api/dwz-store";
import { useParams, useNavigate } from "react-router-dom";


export default function Product() {

  const { id } = useParams();

  const navigate = useNavigate();

  //const [searchParams, setSearchPrams] = useSearchParams();
  //const id = searchParams.get("id") != null ? (searchParams.get("id") as string) : "";

  const [product, setProduct] = useState({} as DProduct);
    
  const [loadingProduct, setLoadingProduct] = useState(true);

  function getProduct(id: string) {
    DWZStore.getProduct(id).then((json) => {
      setProduct(json.data as DProduct);
      console.log("json: " + JSON.stringify(json)); 
      setLoadingProduct(false);
    });
  }

  const getSampleChoiceOptions = (allChoiceOptions: DChoiceOptions[]) => {
    var choiceOptions = [] as DChoiceOption[];
    for (
      var i = 0;
      allChoiceOptions != null && i < allChoiceOptions.length;
      i++
    ) {
      choiceOptions.push({
        name: allChoiceOptions[i].name,
        value: allChoiceOptions[i].values[0],
      });
    }
    return choiceOptions;
  };

  const addToCart = (item: DCartItem) => {
    DWZStore.addToCart(item).then((cart) => {
      const customEvent = new CustomEvent("store:cart:updated", {
        detail: { cart: cart },
      });
      document.dispatchEvent(customEvent);
    });
  };

  // TODO
  const buyNow = (item: DCartItem) => {
    //const customEvent = new CustomEvent("store:cart:updated", {
    //  detail: { product: product },
    //});
    //document.dispatchEvent(customEvent);
    navigate("/store/buy", { state: item});
  };

  useEffect(() => {
    getProduct(id as string);
  }, []);

  return (
    <>
      <Cart detail={false} />
      <Container maxWidth="lg">
        <div className={styles.center}>
          <h1>{ DWZStore.STORE_NAME}</h1>
        </div>
        <div className={styles.card}>
          { DWZStore.STORE_SLOGAN}
        </div>
      </Container>

      <ThemeCard
        backgroundImg="https://dwz-data.s3.amazonaws.com/assets/images/york_01.jpg"
        title=""
        description=""
        minHeight={1000}
        opacity={0.6}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={8}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid container item lg={12} justifyContent="center">
              {loadingProduct ? (
                <CircularProgress color="secondary" sx={{ fontSize: 80 }} />
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                  justifyContent="center"
                >
                  <ProductCard
                    product={product}
                    onAddToCart={(item: DCartItem) => {
                      addToCart(item);
                    }}
                    onBuyNow={(item: DCartItem) => {
                      buyNow(item);
                      //buyNow(product);
                    }}
                    showRetailPrice={true}
                    showPriceOptions={true}
                    showQuantityOptions={true}
                    showAddToCart={true}
                    showBuyNow={true}
                  />
                </Grid>
              )}
            </Grid>

            
          </Grid>
        </Container>
      </ThemeCard>
    </>
  );
}

import {
  Box,
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Tooltip,
  useScrollTrigger,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";

import "./style.scss";
import { useState } from "react";
import { flexbox } from "@mui/system";

export interface ITextField {
  label?: string;
  tip?: string;
  editMode?: boolean;
  name?: string;
  value?: string;
  multiline?: boolean;
  onChange?: any;
  onCommit?: any;
  onDelete?: any;
  onCancel?: any;
}

const BRichTextField = ({
  label,
  tip,
  editMode,
  name,
  value,
  multiline,
  onChange,
  onCommit,
  onDelete,
  onCancel,
}: ITextField) => {
  const [myEditMode, setMyEditMode] = useState(editMode);

  return (
    <div style={{ width: "100%", scrollBehavior: "auto" }}>
      <Box sx={{ width: "100%" }}>
        <h3>
          {tip && (
            <Tooltip title={tip} arrow>
              <HelpOutlineIcon style={{ fontSize: 20, padding: 2 }} />
            </Tooltip>
          )}
        </h3>
      </Box>

      {myEditMode ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-stretch",
            alignContent: "flex-start",
            alignItems: "flex-stretch",
            textAlign: "left",
          }}
        >
          <TextField
            fullWidth
            rows={4}
            required
            multiline={multiline}
            maxRows={40}
            variant="outlined"
            color="primary"
            id="name"
            label={label}
            value={value}
            onChange={onChange}
          />

          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                if (onCommit) {
                  onCommit();
                }
                setMyEditMode(false);
              }}
            >
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                setMyEditMode(false);
                if (onCancel) {
                  onCancel();
                }
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Box
            flexGrow={1}
            sx={{ border: "0px solid silver", p: 1, borderRadius: "15px" }}
          >
            <h3 className="left primary-dark">
              {value ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<span style="white-space: pre-line"><h3>${value}</h3></span>`,
                  }}
                ></div>
              ) : (
                "(not set)"
              )}
            </h3>
          </Box>
          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                setMyEditMode(true);
              }}
            >
              <EditIcon />
            </IconButton>
            {onDelete && (
              <IconButton
                color="warning"
                aria-label="delete"
                component="span"
                onClick={() => {
                  if (onDelete) {
                    onDelete();
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

export default BRichTextField;

import { useEffect, useRef, useState } from "react";

import useScrollTrigger from "../hooks/scroll-trigger";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Fade,
  Grid,
} from "@mui/material/";
import "../custom.d.ts";
import "./style.scss";

import Banner from "../components/banner/banner";
import VideoThemeCard from "../components/card/video-theme-card";

import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SpeedIcon from "@mui/icons-material/Speed";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";
import ThemeCard from "../components/card/theme-card";
import IconCard from "../components/card/icon-card";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import CodeIcon from "@mui/icons-material/Code";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import SchoolIcon from "@mui/icons-material/School";
import { useNavigate } from "react-router-dom";

const PartnerGetStarted = (props: any) => {
  const navigate = useNavigate();

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const [webContent, setWebContent] = useState({} as DWebContent);

  useEffect(() => {
    document.title = "BSG Partner:Get Started";

    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };
    BSGAPI.getWebContent("partner-get-started").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    window.scrollTo(0, 0);

    //myApp.user?.setLinkedInFirstName(myApp.user, "Julian");
  }, []);

  return (
    <div>
      <ThemeCard
        backgroundImg="https://my-bsg-asset.s3.amazonaws.com/image/pic_customer.jpg"
        title=""
        description=""
        minHeight={400}
        opacity={0.4}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignContent="flex-start"
          >
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <div ref={myRefWe}>
                <Fade in={triggerWe} timeout={4000}>
                  <h1 className="warning-light center">
                    {webContent.h1?.title}
                  </h1>
                </Fade>
              </div>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <h2 className="primary-light center">
                {webContent.h1?.description}
              </h2>
              <h3 className="primary-light center">{webContent.h1?.content}</h3>
            </Grid>
          </Grid>
        </Container>
      </ThemeCard>

      <div>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={6}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <h2>BSG Inviting You 歡迎</h2>
              <h4>Here are some of BSG Programs you can be part of as BSG Partner. </h4>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              justifyContent="center"
            >
              <IconCard
                icon={
                  <CardMembershipIcon
                    color="secondary"
                    style={{ fontSize: 64 }}
                  />
                }
                title="Career Coaching"
                description="We accelerate your career development and advancement for professionals at all levels. "
                onClick={() => {
                  navigate("/career-coaching");
                }}
                onClickText="$798 USD"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              justifyContent="center"
            >
              <IconCard
                icon={<CodeIcon color="secondary" style={{ fontSize: 64 }} />}
                title="Professional Coding Camp"
                description="Share our experience to train and develop talents in software development. "
                onClick={() => {
                  //window.location.href = "https://www.bsg.academy/coding-camp";
                  window.open("https://www.bsg.academy/coding-camp", "_blank");
                }}
                onClickText="$3,600 USD"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              justifyContent="center"
            >
              <IconCard
                icon={
                  <AnalyticsOutlinedIcon
                    color="secondary"
                    style={{ fontSize: 64 }}
                  />
                }
                title="Data & AI Camp"
                description="End-to-end hands-on training for data platform, data engineering, data analytics. "
                onClick={() => {
                  //window.location.href = "https://www.bsg.academy/data-ai-camp";
                  window.open("https://www.bsg.academy/data-ai-camp", "_blank");
                }}
                onClickText="$3,600 USD"
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              justifyContent="center"
            >
              <IconCard
                icon={<SchoolIcon color="secondary" style={{ fontSize: 64 }} />}
                title="Other Programs"
                description="Future programs"
              />
            </Grid>
          </Grid>
        </Container>

        {webContent.content && (
          <div className="section-white">
            <Container maxWidth="lg">
              <div>
                <h2>{webContent.h2?.title}</h2>
                <h3>{webContent.h2?.description}</h3>
              </div>
              {webContent.content?.map((content, index) => (
                <Accordion key={`faq-${index}`}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h2 className="secondary-light">{content.title}</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<h3>${content.content}</h3>`,
                      }}
                    ></div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerGetStarted;

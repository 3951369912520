import { useContext, useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";

//import { NVL } from '@neo4j-nvl/base'
//import { BasicNvlWrapper } from '@neo4j-nvl/react'

import type { HitTargets, Node, Relationship } from "@neo4j-nvl/base";
import { InteractiveNvlWrapper } from "@neo4j-nvl/react";
import type { MouseEventCallbacks } from "@neo4j-nvl/react";

import "./style.scss";
import { UserContext } from "../context/user-context";
import { DCompany, DPerson, DUser } from "../model";
import { BSGAuth } from "../auth/bsg-auth";
import { BSGAPI2 } from "../auth/bsg-api2";
import Loading from "../components/loading/loading";
import MemberCard from "../components/card/member-card";
import CompanyCard from "../components/card/company-card";
import Banner from "../components/banner/banner";

import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";

const AcademyNetwork = (props: any) => {
  const history = useNavigate();

  const { id } = useParams();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  // graph nodes and relationships contains UI rendering info (as required by @neo4j-nvl)
  const [graphNodes, setGraphNodes] = useState<Node[]>([]);
  const [graphRelationships, setGraphRelationships] = useState<Relationship[]>(
    []
  );

  // raw data nodes and relationships contain more business data
  const [nodes, setNodes] = useState<any[]>([]);
  const [relationships, setRelationships] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const [record, setRecord] = useState({} as any);

  const init = () => {
    //const nvl = new NVL(document.getElementById('neo4j-container') as HTMLElement, nodes, relationships)
  };

  const [keyword, setKeyword] = useState("");

  const clear = () => {
    setGraphNodes([]);
    setGraphRelationships([]);
    setNodes([]);
    setRelationships([]);
    setRecord({});
  };

  const loadNetwork = (id: string | null, keyword: string | null) => {
    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

        setLoading(true);
        BSGAPI2.getBSGNetwork(context.user.cognitoCredentials, id, keyword)
          .then((json) => {
            //console.log(JSON.stringify(json));
            //{"data":null,"status":"unauthorized"}

            if (json.status != "ok") {
              //console.log("BAD ..." + json.status);
              //return;
              history("/home");
            } else {
              //console.log("OK ...");
              // TODO - replace concat: check if existing before add node or relationship
              setGraphNodes(graphNodes.concat(...json.data["graph"]["nodes"]));
              setGraphRelationships(
                graphRelationships.concat(json.data["graph"]["relationships"])
              );

              setNodes(nodes.concat(json.data["raw"]["nodes"]));
              setRelationships(
                relationships.concat(json.data["raw"]["relationships"])
              );
              setLoading(false);
            }
          })
          .catch((err: any) => {
            //console.log(err);
          });
      } else {
        history("/sign-in");
      }
    });
  };

  const getRawNode = (graphNode: Node) => {
    const rawNode: any = nodes.find((n) => n.id == graphNode.id);
    return rawNode;
  };

  const getRawRelationship = (graphRelationship: Node) => {
    const rawRelationship: any = relationships.find(
      (n) => (n.id = graphRelationship.id)
    );
    return rawRelationship;
  };

  const addElements = () => {
    //const newNodes = [...nodes, { id: nodes.length as  }]
    //setNodes(newNodes)
  };

  const mouseEventCallbacks: MouseEventCallbacks = {
    onHover: (
      element: Node | Relationship,
      hitTargets: HitTargets,
      evt: MouseEvent
    ) => {
      //console.log('onHover', element, hitTargets, evt);
    },
    onRelationshipRightClick: (
      rel: Relationship,
      hitTargets: HitTargets,
      evt: MouseEvent
    ) => {
      //console.log('onRelationshipRightClick', rel, hitTargets, evt)
    },
    onNodeClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) => {
      //console.log('onNodeClick', node, hitTargets, evt);
      const rawNode = getRawNode(node);
      setRecord(rawNode);
    },
    onNodeRightClick: (node: Node, hitTargets: HitTargets, evt: MouseEvent) => {
      //console.log('onNodeRightClick', node, hitTargets, evt);
    },
    onNodeDoubleClick: (
      node: Node,
      hitTargets: HitTargets,
      evt: MouseEvent
    ) => {
      //console.log('onNodeDoubleClick', node, hitTargets, evt);
      //console.log(JSON.stringify(node));
      const rawNode: any = getRawNode(node);
      //console.log(JSON.stringify(rawNode));
      loadNetwork(rawNode.value.id, null);
    },
    onRelationshipClick: (
      rel: Relationship,
      hitTargets: HitTargets,
      evt: MouseEvent
    ) => {
      //console.log('onRelationshipClick', rel, hitTargets, evt);
    },
    onRelationshipDoubleClick: (
      rel: Relationship,
      hitTargets: HitTargets,
      evt: MouseEvent
    ) => {
      //console.log('onRelationshipDoubleClick', rel, hitTargets, evt);
    },
    onCanvasClick: (evt: MouseEvent) => {
      //console.log("onCanvasClick", evt);
    },
    onCanvasDoubleClick: (evt: MouseEvent) => {
      //console.log("onCanvasDoubleClick", evt);
    },
    onCanvasRightClick: (evt: MouseEvent) => {
      //console.log("onCanvasRightClick", evt);
    },
    onDrag: (nodes: Node[]) => {
      //console.log("onDrag", nodes);
    },
    //onPan: (evt: MouseEvent) => { console.log('onPan', evt),
    onPan: true,
    onZoom: (zoomLevel: number) => {
      //console.log('onZoom', zoomLevel);
    },
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Banner>
        <h2>My Network: BSG Academy</h2>
      </Banner>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          padding={4}
          direction="row"
          justifyContent="center"
          justifyItems="center"
          alignItems="stretch"
          alignContent="center"
          textAlign="center"
        >
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          justifyContent="center"
          justifyItems="center"
          alignItems="center"
        >
          <h5>Note: This function is best used on desktop/laptop computers and it may not work properly on mobile or touch screen. </h5>
        </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            justifyContent="center"
            justifyItems="center"
            alignItems="center"
          >
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                alignContent: "flex-end",
                backgroundColor: "white",
              }}
            >
              <PeopleIcon color="secondary" />
              <BusinessIcon color="warning" />
              <TextField
                required
                id="keyword"
                color="secondary"
                variant="outlined"
                label="Search by keyword"
                autoComplete="keyword"
                defaultValue={""}
                value={keyword}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    //submitSearch();
                    loadNetwork(null, keyword);
                  }
                }}
                onChange={(e) => {
                  //setUser({ ...user, username: e.target.value });
                  setKeyword(e.target.value);
                }}
              />
              <Loading show={loading} />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  //submitSearch();
                  loadNetwork(null, keyword);
                }}
              >
                Searh
              </Button>
              <Button
                key="clear-button"
                variant="outlined"
                color="info"
                onClick={() => {
                  clear();
                }}
              >
                Clear
              </Button>
            </Box>

            {nodes.length == 0 ? (
              <h3 className="center warning-dark">
                No records found. Use some meaning keyword to search.
              </h3>
            ) : (
              ""
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            justifyContent="center"
            alignItems="center"
          ></Grid>

          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            justifyContent="center"
            justifyItems="center"
            alignItems="center"
            textAlign="center"
          >
            <InteractiveNvlWrapper
              id="neo4j-container"
              nodes={graphNodes}
              rels={graphRelationships}
              mouseEventCallbacks={mouseEventCallbacks}
              nvlOptions={{ 
                instanceId: "neo4j-wrapper", 
                useWebGL: false, 
                disableWebGL: true, 
                renderer: 'canvas', 
                initialZoom: 1.0 
              }}
              style={{
                width: "100%",
                height: "100%",
                minWidth: 400,
                minHeight: 600,
                padding: 0,
                margin: 0,
                border: "1px solid silver",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            justifyContent="center"
            justifyItems="center"
            alignItems="center"
            textAlign="center"
          >
            <div style={{ padding: 0, width: "100%" }}>
              {record.type == "User" || record.type == "Person" ? (
                <MemberCard member={record.value as DPerson}></MemberCard>
              ) : (
                ""
              )}

              {record.type == "Company" ? (
                <CompanyCard company={record.value as DCompany}></CompanyCard>
              ) : (
                ""
              )}
            </div>
          </Grid>
        </Grid>
      </div>

  );
};

export default AcademyNetwork;

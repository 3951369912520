import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import Banner from "../components/banner/banner";
import { Alert, Button, Chip, Container, Fab, Fade, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DCalendarEvent,
  DCompany,
  DContent,
  DPerson,
  DUser,
  DUserAttribute,
  DWebContent,
} from "../model";
import { ContextService } from "../context/context-service";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import IconCard from "../components/card/icon-card";
import { BSGAPI } from "../auth/bsg-api";
import CompanyCard from "../components/card/company-card";
import { BSGAPI2 } from "../auth/bsg-api2";
import Loading from "../components/loading/loading";
import MemberCard from "../components/card/member-card";
import GroupsIcon from "@mui/icons-material/Groups";
import HubIcon from "@mui/icons-material/Hub";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContentCard from "../components/card/content-card";
import WebContentCard from "../components/card/web-content-card";
import Carousel from "../components/carousel/carousel";
import ScrollCard from "../components/card/scroll-card";
import NewsCard from "../components/card/news-card";
import EventCard from "../components/card/event-card";
import ThemeCard from "../components/card/theme-card";

const CommunityHome = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const navigate = useNavigate();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  //let [loading, setLoading] = useState(true);

  const [loadingEvent, setLoadingEvent] = useState(true);
  const [loadingNews, setLoadingNews] = useState(true);
  const [loadingBusiness, setLoadingBusiness] = useState(true);

  //const [userAttribute, setUserAttribute] = useState({} as DUserAttribute);
  const [companies, setCompanies] = useState([] as DCompany[]);
  const [clients, setClients] = useState([] as DCompany[]);

  const [content, setContent] = useState({ items: [] } as DContent);

  const [page, setPage] = useState(null as any);

  const [sectionItems, setSectionItems] = useState([] as DContent[]);

  const [events, setEvents] = useState([] as DCalendarEvent[]);
  const [newsItems, setNewsItems] = useState([] as DContent[]);
  const [businessItems, setBusinessItems] = useState([] as DCompany[]);

  useEffect(() => {
    //document.title = "BSG Partner:Home";

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

        setLoadingBusiness(true);
        setLoadingNews(true);
        setLoadingEvent(true);

        // loading BSG Events
        BSGAPI2.getEvents(context.user.cognitoCredentials, "BSG").then(
          (response: any) => {
            if (response.status == "ok") {
              //console.log(response.data);
              setEvents(response.data);
              setLoadingEvent(false);
            } else if (response.status == "token-expired") {
              navigate("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
            }
          }
        );

        /****/
        BSGAPI2.getNews(context.user.cognitoCredentials).then(
          (response: any) => {
            if (response.status == "ok") {
              //console.log(response.data);
              setNewsItems(response.data);
              setLoadingNews(false);
            } else if (response.status == "token-expired") {
              navigate("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
            }
          }
        );

        BSGAPI2.getBusiness(context.user.cognitoCredentials, {
          category: "recommended",
        }).then((response: any) => {
          if (response.status == "ok") {
            //console.log(response.data);
            setBusinessItems(response.data as DCompany[]);
            setLoadingBusiness(false);
          } else if (response.status == "token-expired") {
            navigate("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
          }
        });
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        navigate("/sign-in");
      }
    });

    BSGAPI.getWebContent("community").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    BSGAPI.getWebContent("community-news").then((json) => {
      let content = json as DContent;
      setContent(content as DContent);
      if (content.children) {
        if (content.children.length > 0) {
          // main page content (page title, etc.)
          const page = content.children.find((c) => c.key == "page");
          if (page) {
            setPage(page);
          }
          // body content section items:
          const items = content.children.filter((c) => c.key == "section");
          if (items != null) {
            setSectionItems(items);
            //setNewsItems(items);
          }
        }
      }

      //setLoading(false);
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ThemeCard
        backgroundImg="https://my-bsg-asset.s3.amazonaws.com/image/theme_office5.jpg"
        title=""
        description=""
        minHeight={220}
        opacity={0.8}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignContent="flex-start"
          >
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <h1 className="primary-light">{webContent.h1?.title} </h1>
            </Grid>
            {webContent.h1?.children?.map((child) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <h2 className="primary-light">{child.content}</h2>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </ThemeCard>

      <div className="section-light">
        <Container maxWidth="lg">
        <h2 className="secondary-light">Community Events</h2>
        <h3 className="primary-dark">
            Please join our professional networking and other community events.{" "}
          </h3>
        </Container>
      </div>

      <div className="section-white">
        <Container maxWidth="lg">
          {loadingEvent ? (
            <div className="left">
              <Loading show={loadingEvent} />
            </div>
          ) : (
            <div>
              {events.length == 0 && (
                <Alert variant="outlined" severity="info">
                  No community events found. Please check back later.{" "}
                </Alert>
              )}

              {events.map((item: any, index: number) => {
                return <EventCard event={item} key={`section-${index}`} />;
              })}
            </div>
          )}
        </Container>
      </div>


      <div className="section-light">
        <Container maxWidth="lg">

        <h2 className="secondary-light">Community News</h2>
        <h3 className="primary-dark">Find out what is going on at the BSG Community. </h3>
        </Container>
      </div>

      <div className="section-white">
        <Container maxWidth="lg">

          {loadingNews ? (
            <div className="left">
              <Loading show={loadingNews} />
            </div>
          ) : (
            <div>
                            {newsItems.length == 0 && (
                <Alert variant="outlined" severity="info">
                  No community news found. Please check back later.{" "}
                </Alert>
              )}

              {newsItems.map((item: any, index: number) => {
                return <NewsCard content={item} key={`section-${index}`} />;
              })}
            </div>
          )}
        </Container>
      </div>

      <div className="section-light">
        <Container maxWidth="lg">
        <h2 className="secondary-light">Community Services</h2>
          <h3 className="primary-dark">
          Find out what services are available to you in the BSG Community.{" "}
          </h3>
          <div className="left">
          <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                navigate("/business");
              }}
            >
              See all recommneded services
            </Button>
            </div>
        </Container>
      </div>

      <div className="section-white">
        <Container maxWidth="lg">
          {loadingBusiness && (
            <div className="left">
              <Loading show={loadingBusiness} />
            </div>
          )}
        </Container>

        {!loadingBusiness && (
          <div className="left">
            <Carousel items={businessItems}></Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityHome;

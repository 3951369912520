/**
 * 

new Date()
getDay() = week day (e.g.  0 = Sunday, 1 = Monday )


 */

export class BSGCalendar {
  static instance: BSGCalendar = new BSGCalendar();

  static MONTH = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  now: Date = new Date();

  constructor() {}

  static config() {
    //console.log("This is config of my Auth class");
  }

  static current() {
    return BSGCalendar.instance;
  }

  firstDayOfMonth() {
    return new Date(this.now.getFullYear(), this.now.getMonth(), 1);
  }

  lastDayOfMonth() {
    return new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
  }

  firstDayOnCalendar() {
    let date = this.firstDayOfMonth();
    return new Date(date.getTime() - 3600 * 24 * 1000 * date.getDay());
  }

  getDate(date: Date, dayOffSet: number) {
    return new Date(date.getTime() + 3600 * 24 * 1000 * dayOffSet);
  }
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Container, Fade, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DPerson, DUser } from "../model";
import { ContextService } from "../context/context-service";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import IconCard from "../components/card/icon-card";

export interface Props {
  text: string;
  /**
     * 
    ok: boolean;
    id?: number;  // ? optional
    fn: (bob: string) => string;
    person: Person;
     */
}

const MyDocument = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [gdrive, setGdrive] = useState(context.user.person?.gdrive);

  const classes: any = styles(theme);

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        forceUpdate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    document.title = `${ContextService.SITE_NAME}:My Documents`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>My Documents</h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h2>You will find your documents here</h2>
          </Grid>
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="md">
          <Grid
            container
            spacing={6}
            justifyContent="center"
            alignItems="stretch"
          >
            {gdrive && (
              <Grid
                item
                xs={10}
                sm={6}
                md={4}
                lg={4}
                justifyContent="center"
                alignContent="center"
              >
                <IconCard
                  icon={
                    <InsertDriveFileIcon
                      color="secondary"
                      style={{ fontSize: 64 }}
                    />
                  }
                  title="Google Drive"
                  description="Please find your documents here"
                  onClick={() => {
                    window.open(
                      `https://drive.google.com/drive/u/1/folders/${gdrive}`,
                      "_blank"
                    );
                  }}
                  onClickText="Open G-Drive"
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default MyDocument;

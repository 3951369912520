import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Container, Fade, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DPerson, DUser, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import { BSGAPI } from "../auth/bsg-api";
import { BSGAPI2 } from "../auth/bsg-api2";
import PersonCard from "../components/card/person-card";
import Loading from "../components/loading/loading";

import MuiAlert, { AlertProps } from "@mui/material/Alert";

const MyTest = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const [webContent, setWebContent] = useState({} as DWebContent);

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const classes: any = styles(theme);


  let [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [objects, setObjects] = useState([] as DPerson[]); 

  //const [data, setData] = useState({} as any); 
  const [data, setData] = useState([] as any); 


  useEffect(() => {
    document.title = "BSG Partner:Test";

    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        forceUpdate();

        /***/ 
        BSGAPI2.getPaymentHistory(
          context.user.cognitoCredentials,
        ).then((json) => {
          //console.log(json);
          if(json.status == 'ok') {
            //console.log("OK ..."); 
            setData(json.data); // for json type testing
            setLoading(false); 
          } else {
            //console.log("BAD ..." + json.status); 
            setError(json.status);
            setShowError(true); 
          }
        });
        /***/

      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/my-test`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });
    BSGAPI.getWebContent("test").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">


        {loading ? (
            <div>
              <Loading show={loading} />
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="center"
              >
                {showError && (
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                      setShowError(false);
                    }}
                    severity="error"
                  >
                    {error}
                  </MuiAlert>
                )}
              </Grid>
            </div>
          ) : (
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
<Grid
                    key={`grid-007`}
                    container
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    justifyContent="center"
                  >
                    { JSON.stringify(data) }
                  </Grid>

{objects?.map((object, index) => {
                return (
                  <Grid
                    key={`grid-${index}-${object.id}`}
                    container
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    justifyContent="center"
                  >
                    {object && (
                        <PersonCard key={`person-${index}-${object.id}`}
                        fname={object.fname as string}
                        lname={object.lname as string}
                        picture={object.picture as string}
                        title={object.title as string}
                        subtitle={object.subtitle as string}
                        description={object.description as string}
                      >
                        <span>{object.description as string}</span>
                      </PersonCard>
                    )}
                  </Grid>
                );
              })}


<pre>
{ JSON.stringify(objects, null, 3) }
</pre>
          </Grid>
          )}

        </Container>
      </div>
    </div>
  );
};

export default MyTest;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";
import { Button, Chip, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DCompany, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconCard from "../components/card/icon-card";
import { BSGAPI } from "../auth/bsg-api";
import CompanyCard from "../components/card/company-card";
import { BSGAPI2 } from "../auth/bsg-api2";
import ThemeCard from "../components/card/theme-card";
import Loading from "../components/loading/loading";

const MyHome = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const [paymentTotal, setPaymentTotal] = useState(0.0);
  const [opportunityTotal, setOpportunityTotal] = useState(0);
  //const [userAttribute, setUserAttribute] = useState({} as DUserAttribute);
  const [companies, setCompanies] = useState([] as DCompany[]);
  const [clients, setClients] = useState([] as DCompany[]);

  const [loadingPayment, setLoadingPayment] = useState(true);
  const [loadingReferral, setLoadingReferral] = useState(true);

  useEffect(() => {
    document.title = "BSG Partner:Home";

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {

      //console.log("I am here ..." +JSON.stringify(context.user));
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.Username);

        forceUpdate();

        // Initial User
        BSGAPI2.initUser(context.user.cognitoCredentials).then(
          (response: any) => {
            if (response.status == "ok") {
              //console.log("init user: " + JSON.stringify(response.data));
              forceUpdate();
            } else if (response.status == "token-expired") {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
              history("/sign-in");
            }
          }
        );

        // get company (as Client manager)
        BSGAPI.getCompanies(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((response: any) => {
          //setOpportunityTotal(parseInt(response.data.total));
          //console.log("response: " + JSON.stringify(response));

          if (response.status == "ok") {
            setCompanies(response.data as DCompany[]);
          } else if (response.status == "token-expired") {
            history("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
            history("/sign-in");
          }
        });

        // get clients as BSG manager
        BSGAPI.getClients(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((response: any) => {
          if (response.status == "ok") {
            setClients(response.data as DCompany[]);
          } else if (response.status == "token-expired") {
            history("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
            history("/sign-in");
          }
        });

        // load opportunity total
        setLoadingReferral(true);
        if (context.user.ui?.includes("item_referral")) {
          BSGAPI2.getReferrals(context.user.cognitoCredentials).then((json) => {
            if (json.status == "ok") {
              setOpportunityTotal((json.data as any[]).length);
            } else if (json.status == "token-expired") {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
              history("/sign-in");
            }

            setLoadingReferral(false);
          });
        }

        // load payment summary

        if (context.user.ui?.includes("item_payment")) {
          /**
          BSGAPI.getPaymentSummary(
            context.user.cognitoCredentials,
            context.user.cognitoUser,
            context.user.person!.id as string
          ).then((response: any) => {
            if (response.status == "ok") {
              setPaymentTotal(parseFloat(response.data.total));
            } else if (response.status == "token-expired") {
              //history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
            }
          });
          **/
          setLoadingPayment(true);
          BSGAPI2.getPaymentHistory(context.user.cognitoCredentials).then(
            (json) => {

              if (json.status == "ok") {
              
              //console.log("Payment history: " + JSON.stringify(json));
              let sum: number = 0;
              for (let i = 0; i < (json.data as any[]).length; i++) {
                sum += json.data[i].amount;
              }
              setPaymentTotal(sum);
            } else if (json.status == "token-expired") {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
              history("/sign-in");
            }

              //setPayments(json.data as DPayment[]);
              setLoadingPayment(false);
            }
          );
        }
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    BSGAPI.getWebContent("home").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ThemeCard
        backgroundImg="https://dwz-data.s3.amazonaws.com/assets/images/dragonboat_01.jpg"
        title=""
        description=""
        minHeight={500}
        opacity={0.6}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignContent="flex-start"
          >
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <h1 className="primary-light">{webContent.h1?.title} </h1>
            </Grid>
            {webContent.h1?.children?.map((child) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <h2 className="primary-light">{child.content}</h2>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </ThemeCard>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              justifyContent="center"
              alignContent="center"
            >
              <div>
                <h3>
                  Are you new? Click here to learn more and get started ...{" "}
                </h3>

                <Button
                  variant="contained"
                  size="medium"
                  color="warning"
                  onClick={() => {
                    history("/get-started");
                  }}
                >
                  Get Started
                </Button>
              </div>
            </Grid>
            {context.user.ui?.includes("item_my_profile") && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                justifyContent="center"
                alignContent="center"
              >
                <IconCard
                  icon={
                    <AssignmentIndIcon color="info" style={{ fontSize: 64 }} />
                  }
                  title="My Profile"
                  description="Keep in touch"
                  onClick={() => {
                    history("/profile");
                  }}
                  onClickText="Review"
                >
                  <Chip
                    label={"Please review"}
                    variant="outlined"
                    color="warning"
                    size="medium"
                    sx={{ fontSize: "16pt", p: 3 }}
                    onClick={() => {
                      history("/profile");
                    }}
                  ></Chip>
                </IconCard>
              </Grid>
            )}

            {context.user.ui?.includes("item_payment") && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                justifyContent="center"
                alignContent="center"
              >
                <IconCard
                  icon={
                    <AttachMoneyIcon color="info" style={{ fontSize: 64 }} />
                  }
                  title="My Pay "
                  description="You've made amount"
                  onClick={() => {
                    history("/pay");
                  }}
                  onClickText="Review"
                >
                  <Chip
                    label={`$${paymentTotal}`}
                    variant="outlined"
                    color="warning"
                    icon={<Loading show={loadingPayment} />}
                    size="medium"
                    sx={{ fontSize: "16pt", p: 3 }}
                    onClick={() => {
                      history("/pay");
                    }}
                  ></Chip>
                </IconCard>
              </Grid>
            )}
            {context.user.ui?.includes("item_referral") && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                justifyContent="center"
                alignContent="center"
              >
                <IconCard
                  icon={<PeopleAltIcon color="info" style={{ fontSize: 64 }} />}
                  title="Referrals"
                  description="You've referred people"
                  onClick={() => {
                    history("/referral");
                  }}
                  onClickText="Review"
                >
                  <Chip
                    label={`${opportunityTotal}`}
                    variant="outlined"
                    color="warning"
                    size="medium"
                    icon={<Loading show={loadingReferral} />}
                    sx={{ fontSize: "16pt", p: 3 }}
                    onClick={() => {
                      history("/referral");
                    }}
                  ></Chip>
                </IconCard>
              </Grid>
            )}
            {companies?.map((company) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <CompanyCard
                    company={company}
                    route={`/candidates/${company.id}`}
                    routeText="Job Candidates"
                  ></CompanyCard>
                </Grid>
              );
            })}

            {clients?.map((client) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <CompanyCard
                    company={client}
                    route={`/candidates/${client.id}`}
                    routeText="Job Candidates"
                  ></CompanyCard>
                </Grid>
              );
            })}

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              justifyContent="center"
              alignContent="center"
            >
              <div>
                <h3 className="center">
                  Sit tight while we are upgrading our partner site.{" "}
                </h3>
                <h3 className="center">
                  Please report any issues and/or suggest new features.{" "}
                </h3>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default MyHome;

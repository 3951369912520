import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/user-context";

import { theme, styles } from "../../theme/Theme";
import Banner from "../../components/banner/banner";
import { Container, Grid } from "@mui/material";
import GoogleSlideCard from "../../components/card/google-slide-card";
import { useNavigate } from "react-router";
import { DUser } from "../../model";
import { BSGAuth } from "../../auth/bsg-auth";

import { NVL } from '@neo4j-nvl/base'


export interface Props {
  text: string;
}

export default function Test() {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { context } = useContext(UserContext);
  const classes: any = styles(theme);

  const myRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    //context.getCognitoJWT().then((jwt) => {
    //  console.log("Cognito JWT Token: " + jwt);
    //});

    BSGAuth.config();

    context.cognitoGetUser().then((user: DUser) => {
      if (user.cognitoUser?.Username) {

        
      } else {
        history("/sign-in");
      }
    });

    const myNvl = new NVL(
      document.getElementById('frame') as HTMLElement,
      [{ id: '0' }, { id: '1' }],
      [{ id: '10', from: '0', to: '1' }],
      { initialZoom: 2 },
      { onLayoutDone: () => {
        //console.log('layout done')
       } 
      }
    )

  });

  return (
    <div>
      <div id="frame">
        
</div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>一次报名 长期受益</h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h2>
              下面列出了本培训计划提供的职业发展服务的细节项目。
              BSG一直在持续的改进和增加培训的价值，并引入更多的服务项目来支持新老学员的职业发展需要。我们的老学员也一直在和我们一起发展的旅程中，获得更多的价值。{" "}
            </h2>
          </Grid>
        </Grid>
      </Banner>

      <h1>{t("welcome.title")}</h1>
      <div className={classes.navHeader}>This is a test</div>
      <button
        onClick={() => {
          context.setLanguage("cn");
          i18n.changeLanguage("cn");
        }}
      >
        中文
      </button>
      <button
        onClick={() => {
          context.setLanguage("en");
          i18n.changeLanguage("en");
        }}
      >
        en
      </button>
      <h3>My name is: {context.user.language} </h3>

      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
          ref={myRef}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>一次报名 长期受益</h1>

            <Container maxWidth="lg">
              <GoogleSlideCard
                src="2PACX-1vTNBQZ1AwdLcxZsOz8pLpx0Y9dNX8G6MYLMHGRUtvKcfh02Wqe-8bIl6DS5fibxS5eZjev1FbVaFII0"
                title="BSG Career Coaching Program Introduction"
                description="BSG Career Coaching Program Introduction"
              ></GoogleSlideCard>
            </Container>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h2>
              下面列出了本培训计划提供的职业发展服务的细节项目。
              BSG一直在持续的改进和增加培训的价值，并引入更多的服务项目来支持新老学员的职业发展需要。我们的老学员也一直在和我们一起发展的旅程中，获得更多的价值。{" "}
            </h2>
          </Grid>
        </Grid>
      </Banner>
    </div>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/user-context";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Hidden,
  Slide,
  useScrollTrigger,
  useTheme,
} from "@mui/material";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MapIcon from "@mui/icons-material/Map";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PieChartIcon from "@mui/icons-material/PieChart";
import AppsIcon from "@mui/icons-material/Apps";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import HearingIcon from "@mui/icons-material/Hearing";
import WorkIcon from "@mui/icons-material/Work";
import TodayIcon from "@mui/icons-material/Today";
import GroupsIcon from "@mui/icons-material/Groups";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import { useNavigate } from "react-router-dom";
import MobileMenu from "./mobile-menu";
import { styles } from "../../theme/Theme";

import "./style.scss";
import logo from "./logo.png";
import { ApplicationContext } from "../../context/application-context";
import { DProfile } from "../../model";
import { ShoppingBag, ShoppingBasket } from "@mui/icons-material";

const anchor: "bottom" | "left" | "right" | "top" = "left";

const useStyles2 = () => {
  return makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });
};

const Nav = (props: any) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  const { t, i18n } = useTranslation("content");

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  let [profile, setProfile] = useState({} as DProfile);

  const [ui, setUi] = useState([] as string[]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      //if ((event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const routeToDrawer = (anchor: any, route: string) => {
    history(route);

    //toggleDrawer(anchor, false);
    setState({ ...state, [anchor]: false });

    handleClose();
  };

  const history = useNavigate();

  const theme = useTheme();
  const classes: any = styles(theme);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorElTraining, setAnchorElTraining] = useState<null | HTMLElement>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
        setUi(context.user.ui as string[]);
      } else {
        setUi([]);
      }
    });
  }, [forceUpdate]);

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar className="navbar">
        <Toolbar className="toolbar">
          {context.user.cognitoUser.Username ? (
            <Button className="navButton" onClick={toggleDrawer(anchor, true)}><img src={logo} alt={"BSG Logo"} style={{ width: 30, padding: 0, margin: 0 }} /></Button>
          ) : (
            <Button
              className="navButton"
              onClick={() => {
                history("/home");
              }}
            ><img src={logo} alt={"BSG Logo"} style={{ width: 30, padding: 0, margin: 0 }} /></Button>
          )}

          <Button className="navButton" component={RouterLink} to="/home">
            MY BSG
          </Button>

          {ui.includes("item_academy") && (
            <Button className="navButton" component={RouterLink} to="/academy">
              ACADEMY
            </Button>
          )}

          {ui.includes("item_community") && (
            <Button
              className="navButton"
              component={RouterLink}
              to="/community"
            >
              COMMUNITY
            </Button>
          )}

            {ui.includes("item_store") && (
              <Button className="navButton" 
              sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
              component={RouterLink} to="/Store">
                STORE
              </Button>
            )}

            {ui.includes("item_job") && (
              <Button className="navButton"
              sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }} component={RouterLink} to="/jobs">
                JOBS
              </Button>
            )}

            {ui.includes("item_training") && (
              <Button
                className="navButton"
              sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  history("/training");
                  setAnchorElTraining(e.currentTarget);
                }}
              >
                TRAINING
              </Button>
            )}
            <Menu
              id="menu-training"
              anchorEl={anchorElTraining}
              keepMounted
              open={Boolean(anchorElTraining)}
              onClose={() => {
                setAnchorElTraining(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  history("/career-coaching");
                  setAnchorElTraining(null);
                }}
              >
                Career Coaching
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history("/coming-soon");
                  setAnchorElTraining(null);
                }}
              >
                Tech Training
              </MenuItem>
            </Menu>

          <div className="grow"></div>

            <MobileMenu></MobileMenu>
          </Toolbar>

        {context.user.cognitoUser.Username ? (
          <Drawer
            anchor={anchor}
            open={state["left"]}
            onClose={toggleDrawer(anchor, false)}
          >
            <List>
              <div style={{ width: "100%", textAlign: "right" }}>
                <IconButton
                  color="warning"
                  aria-label="close drawer"
                  onClick={() => {
                    setState({ ...state, [anchor]: false });
                  }}
                >
                <CloseIcon color="warning" sx={{ fontSize: "30px" }} />
                </IconButton>
              </div>

              {ui.includes("header_mybsg") && (
                <>
                  <div className="navItemHeader">BSG</div>

                  {ui.includes("item_admin") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="admin"
                      onClick={() => {
                        routeToDrawer(anchor, "/admin");
                      }}
                    >
                      <ListItemIcon>
                        <AdminPanelSettingsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="ADMIN" />
                    </ListItem>
                  )}

                  <ListItem
                    dense={true}
                    divider={true}
                    button
                    key="admin"
                    onClick={() => {
                      routeToDrawer(anchor, "/home");
                    }}
                  >
                    <ListItemIcon>
                      <HomeIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText className="navItemText" primary="MY BSG" />
                  </ListItem>

                  {ui.includes("item_community") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="community"
                      onClick={() => {
                        routeToDrawer(anchor, "/community");
                      }}
                    >
                      <ListItemIcon>
                        <GroupsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="COMMUNITY"
                      />
                    </ListItem>
                  )}

                  {ui.includes("item_academy") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="test"
                      onClick={() => {
                        routeToDrawer(anchor, "/academy");
                      }}
                    >
                      <ListItemIcon>
                        <SchoolIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="ACADEMY" />
                    </ListItem>
                  )}

                  {ui.includes("item_store") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="store-list-item"
                      onClick={() => {
                        routeToDrawer(anchor, "/store");
                      }}
                    >
                      <ListItemIcon>
                        <ShoppingBasket color="secondary" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="STORE" />
                    </ListItem>
                  )}

                  {ui.includes("item_schedule") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="schedule"
                      onClick={() => {
                        routeToDrawer(anchor, "/schedule");
                      }}
                    >
                      <ListItemIcon>
                        <TodayIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="SCHEDULE"
                      />
                    </ListItem>
                  )}

                  {ui.includes("item_document") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="documents"
                      onClick={() => {
                        routeToDrawer(anchor, "/documents");
                      }}
                    >
                      <ListItemIcon>
                        <InsertDriveFileIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="DOCUMENTS"
                      />
                    </ListItem>
                  )}
                </>
              )}

              {ui.includes("header_project") && (
                <>
                  <div className="navItemHeader">PROJECTS</div>

                  <ListItem
                    dense={true}
                    divider={true}
                    button
                    key="projects"
                    onClick={() => {
                      routeToDrawer(anchor, "/signin");
                    }}
                  >
                    <ListItemIcon>
                      <AppsIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText className="navItemText" primary="PROJECTS" />
                  </ListItem>

                  <ListItem
                    dense={true}
                    divider={true}
                    button
                    key="signin"
                    onClick={() => {
                      routeToDrawer(anchor, "/signin");
                    }}
                  >
                    <ListItemIcon>
                      <PieChartIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText className="navItemText" primary="CLIENTS" />
                  </ListItem>
                </>
              )}

              {ui.includes("header_recruting") && (
                <>
                  <div className="navItemHeader">Recruiting</div>
                  {ui.includes("item_task") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="tasks"
                      onClick={() => {
                        routeToDrawer(anchor, "/signin");
                      }}
                    >
                      <ListItemIcon>
                        <EmailIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="TASKS" />
                    </ListItem>
                  )}

                  {ui.includes("item_position") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="test-1"
                      onClick={() => {
                        routeToDrawer(anchor, "/member/test");
                      }}
                    >
                      <ListItemIcon>
                        <WorkIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="POSITIONS"
                      />
                    </ListItem>
                  )}

                  {ui.includes("item_candidate") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="candidates"
                      onClick={() => {
                        routeToDrawer(anchor, "/candidates");
                      }}
                    >
                      <ListItemIcon>
                        <PeopleAltIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="CANDIDATES"
                      />
                    </ListItem>
                  )}
                </>
              )}

              {ui.includes("header_system") && (
                <>
                  <div className="navItemHeader">SETTINGS</div>

                  {ui.includes("item_my_profile") && (
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="my-profile"
                      onClick={() => {
                        routeToDrawer(anchor, "/profile");
                      }}
                    >
                      <ListItemIcon>
                        <AssignmentIndIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="PROFILE" />
                    </ListItem>
                  )}

                  <ListItem
                    dense={true}
                    divider={true}
                    button
                    key="change-password"
                    onClick={() => {
                      routeToDrawer(anchor, "/change-password");
                    }}
                  >
                    <ListItemIcon>
                      <LockIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      className="navItemText"
                      primary="CHANGE PASSWORD"
                    />
                  </ListItem>

                  <ListItem
                    dense={true}
                    divider={true}
                    button
                    key="sign-out"
                    onClick={() => {
                      routeToDrawer(anchor, "/sign-out");
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText className="navItemText" primary="LOGOUT" />
                  </ListItem>
                </>
              )}
            </List>
          </Drawer>
        ) : (
          ""
        )}
      </AppBar>
    </Slide>
  );
};

export default Nav;

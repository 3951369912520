import { Card, Container, Grid } from "@mui/material";
import styles from "./layout.module.css";
import PaypalButton from "./components/paypal-button";
import { useEffect, useState } from "react";
import { DWZStore } from "./api/dwz-store";
import CartSummary from "./components/cart-summary";
export default function Checkout() {
  const [cart, setCart] = useState(null as any);

  const loadCart = () => {
    DWZStore.getCart().then((cart) => {
      setCart(cart);
    });
  };

  useEffect(() => {
    loadCart();
    //setCart(DWZStore.getCart());
    //setTotalItems(DWZStore.countTotalItems(cart));
  }, []);

  return (
    <Container maxWidth="md">
      <div className={styles.center}>
        <h1>{ DWZStore.STORE_NAME} - Check Out</h1>
      </div>

      <Card>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          spacing={2}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            {cart != null ? <CartSummary cart={cart} expanded={false}/> : ""}
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            justifyItems="stretch"
          >
            <div>{cart != null ? 
              <PaypalButton cart={cart} /> : ""}
              </div>
          </Grid>

        </Grid>
      </Card>
    </Container>
  );
}

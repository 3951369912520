"use client";

import React, { useEffect, useState } from "react";
import "./style.css";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Grid,
  Hidden,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { useNavigate } from "react-router-dom";
import { DCart, DCartItem, DProduct } from "../model";
import {
  DeleteOutline,
  ExpandMore,
  Handshake,
  Payment,
  ThumbUp,
  TouchAppRounded,
} from "@mui/icons-material";
import { DWZStore } from "../api/dwz-store";
import { deepOrange, green, purple } from "@mui/material/colors";
import ProductCard from "./product-card";
import CartItem from "./cart-item";
import DebugCard from "../../components/card/debug-card";

/**
 * This is Cart component view (different from Cart page view)
 * CartSummary - implement summary view for a Cart (with list of cart items and total # of items and total $price)
 * @param props
 * @returns
 */
const PaypalSummary = (props: any) => {
  const router = useNavigate();

  const [paypal, setPaypal] = useState(props.paypal as any);

  useEffect(() => {}, []);

  return (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box
          sx={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
            justifySelf: "center",
            alignSelf: "center",
            display: "flex",
          }}
        >
          <Avatar sx={{ bgcolor: purple[100], marginRight: "10px" }}>
            <Payment
              style={{
                fontSize: 36,
              }}
              color="warning"
            />
          </Avatar>
          <h3>Paypal Summary</h3>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        style={{
          textAlign: "center",
          border: "0px solid red",
          padding: "20px 10px 20px 10px",
          width: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          columnSpacing={4}
          rowSpacing={8}
          justifyContent="center"
          justifyItems="center"
          alignItems="stretch"
          textAlign="center"
          alignContent="center"
        >
          {paypal != null ? (
            <>
              <Grid
                container
                item
                lg={12}
                direction="row"
                columnSpacing={2}
                rowSpacing={2}
                rowGap={2}
                alignItems="stretch"
                justifyContent="center"
                textAlign="left"
                key={`grid-paypal`}
              >
                <DebugCard
                  title={"Paypal Payment Details"}
                  minHeight={200}
                  description={""}
                >
                  <pre>{JSON.stringify(paypal, null, 1)}</pre>
                </DebugCard>
              </Grid>
            </>
          ) : (
            <Alert
              icon={<NearbyErrorIcon fontSize="large" />}
              severity="warning"
              sx={{ marginTop: "40px", marginBottom: "40px", fontSize: "18px" }}
            >
              No Paypal information is available.
            </Alert>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PaypalSummary;

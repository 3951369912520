import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress, Container } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmailIcon from "@mui/icons-material/Email";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "../context/user-context";
import GoogleIcon from "@mui/icons-material/Google";
import ThemeCard from "../components/card/theme-card";

import logo from "../components/nav/logo.png";
import { ContextService } from "../context/context-service";

import "url-search-params-polyfill";

//const email: string = "";
//const password: string = "";

//const currentConfig = Auth.configure();
//console.log("Current Config: \n\n" + JSON.stringify(currentConfig));

const SignIn = (props: any) => {


  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const history = useNavigate();

  //const location  = useLocation();
  //const searchParams = new URLSearchParams(location.search);
  const [searchParams, setSearchPrams] = useSearchParams();

  const oauthCode = searchParams.get("code") as string;
  const authFlow = searchParams.get("flow") as string;

  let myUri = window.location.href;

  //let redirectUri = '';
  let redirectUri = searchParams.get("redirect-uri")
    ? searchParams.get("redirect-uri")?.split("?")[0] // get URI without parameters
    : myUri.split("?")[0];

  const [user, setUser] = useState({ username: "", password: "" });

  const [step, setStep] = useState(0);

  /**
   * Use user name and password to login
   */
  const signIn = () => {
    context
      .cognitoSignin(user.username, user.password)
      .then(() => {
        //forceUpdate();
        // happy path: successfully logged in

        //getMyProfile();
        //context.bsgGetPerson().then((person) => {
        //  console.log("Got person: " + JSON.stringify(person));
        //});

        setStep(2); // success

        const timer = setTimeout(() => {
          //let redirectLink = context.user.redirectLink;
          forceUpdate();
          if (context.user.cognitoState) {
            let redirectLink = context.user.cognitoState.redirectLink;
            //console.log(
            //  "cognitoState: " + JSON.stringify(context.user.cognitoState)
            //);
            if (redirectLink && redirectLink.uri && redirectLink.uri != "") {
              if (redirectLink.external) {
                window.location.href = redirectLink.uri;
              } else {
                history(redirectLink.uri);
              }
            }
          } else {
            history("/home");
            //var home = context.user.ui?.includes("item_admin")
            //  ? "/admin"
            //  : "/home";
            //history(home);
          }
        }, 1000);
      })
      .catch((err) => {
        //console.log("Login failed: " + err);
        // error path - failed login

        setStep(1); // failed
      });
  };

  /**
   * Initial OAuth flow for login
   */
  const initOAuthSignIn = () => {
    let cognitoState = context.setCognitoState({
      timestamp: new Date().getTime(),
      redirectLink: {
        external: false,
        uri: "",
        //external: true,
        //uri: "https://my.bostonsoftwaregroup.com",
      },
    });

    let oauthLink = `https://bostonsoftwaregroup.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=7m39ip0e8kdbtvodk1o04vo0jm&redirect_uri=${context.urlRoot}/sign-in`;

    //console.log(oauthLink);
    window.location.href = oauthLink;
  };

  /**
   * Use OAuth (Google Identity) to login
   */
  async function oAuthSignIn2(oauthCode: string, redirectUri: string) {
    //console.log("[BSG] oAuthSignIn ...");
    context
      .cognitoOAuthSignin(oauthCode as string, redirectUri as string)
      .then(() => {
        //console.log("[BSG] successfully logged in ... ");
        setStep(2); // success

        const timer = setTimeout(() => {
          forceUpdate();
          if (context.user.cognitoState) {
            let redirectLink = context.user.cognitoState.redirectLink;
            //console.log(
            //  "cognitoState: " + JSON.stringify(context.user.cognitoState)
            //);
            if (redirectLink && redirectLink.uri && redirectLink.uri != "") {
              if (redirectLink.external) {
                window.location.href = redirectLink.uri;
              } else {
                history(redirectLink.uri);
              }
            }
          } else {
            history("/home");
            //let home = context.user.ui?.includes("item_admin")
            //  ? "/admin"
            //  : "/home";
            //history(home);
          }
        }, 3000);
      })
      .catch((err) => {
        //console.log("Login failed: ");
        setStep(1); // failed
      });
  }

  async function oAuthSignIn() {
    //console.log("[BSG] oAuthSignIn ...");
    await context
      .cognitoOAuthSignin(oauthCode, redirectUri as string)
      .then(() => {
        //console.log("[BSG] successfully logged in ... ");
        setStep(2); // success
        const timer = setTimeout(() => {
        //setStep(2);
        forceUpdate();
        if (context.user.cognitoState) {
          let redirectLink = context.user.cognitoState.redirectLink;
          //console.log(
          //  "cognitoState: " + JSON.stringify(context.user.cognitoState)
          //);
          if (redirectLink && redirectLink.uri && redirectLink.uri != "") {
            if (redirectLink.external) {
              window.location.href = redirectLink.uri;
            } else {
              history(redirectLink.uri);
            }
          }
        } else {
          history("/home");
          //let home = context.user.ui?.includes("item_admin")
          //  ? "/admin"
          //  : "/home";
          //history(home);
        }
        }, 3000);
      })
      .catch((err) => {
        //console.log("Login failed: " + err);
        setStep(1); // failed
      });
  }

  /**
   * Refresh OAuth token flow for authentication
   */
  async function refreshToken() {
    await context
      .cognitoRefreshToken(
        context.user.cognitoCredentials.RefreshToken as string
      )
      .then(() => {
        //console.log("[BSG] successfully logged in ... ")
        setStep(2); // success

        const timer = setTimeout(() => {
          //let redirectLink = context.user.redirectLink;
          forceUpdate();
          if (context.user.cognitoState) {
            let redirectLink = context.user.cognitoState.redirectLink;
            //console.log("cognitoState: " + JSON.stringify(context.user.cognitoState));
            if (redirectLink && redirectLink.uri && redirectLink.uri != "") {
              if (redirectLink.external) {
                window.location.href = redirectLink.uri;
              } else {
                history(redirectLink.uri);
              }
            }
          } else {
            //let home = context.user.ui?.includes("item_admin")
            //  ? "/admin"
            //  : "/home";
            history("/home");
          }
        }, 3000);
      })
      .catch((err) => {
        //console.log("Login failed: ");
        setStep(1); // failed
      });
  }

  useEffect(() => {

    //console.log("[BSG] code: " + oauthCode);
    //console.log("[BSG] Auth Flow: " + authFlow);

    //let timer = null as any;
    if (oauthCode) {
      oAuthSignIn();
    } else if (authFlow == "REFRESH_TOKEN_AUTH") {
      refreshToken();
    }

    document.title = `${ContextService.SITE_NAME}:Sign In`;
    window.scrollTo(0, 0);

    //return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <ThemeCard
        backgroundImg="https://my-bsg-asset.s3.amazonaws.com/image/theme_madrid6.jpg"
        opacity={0.9}
        title=""
        description=""
        minHeight={800}
        caption="Madrid, Spain"
      >
        <Container maxWidth="sm">
          <div className="section-white">
            <h3 className="center secondary-light">
              <img src={logo} alt={"BSG Logo"} style={{ width: 45 }} />
              <br />
              BSG Partner Sign In
            </h3>
            <h1>
              <br />
            </h1>
            <Grid container spacing={4}>
              {step == 2 && (
                <Grid item xs={12}>
                  <h3>
                    <h1 className="center secondary-light">
                      <CircularProgress
                        color="secondary"
                        sx={{ fontSize: 80 }}
                      />
                    </h1>
                  </h3>
                </Grid>
              )}
            </Grid>

            {step != 2 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <EmailIcon color="secondary" />
                  <TextField
                    required
                    id="username"
                    color="secondary"
                    variant="outlined"
                    label="Email"
                    autoComplete="email"
                    defaultValue={user.username}
                    onChange={(e) => {
                      setUser({ ...user, username: e.target.value });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <VpnKeyIcon color="secondary" />
                  <TextField
                    required
                    id="password"
                    variant="outlined"
                    color="secondary"
                    label="Password"
                    type="password"
                    defaultValue={user.password}
                    onChange={(e) => {
                      setUser({ ...user, password: e.target.value });
                    }}
                  />
                </Grid>

                {step == 1 && (
                  <Grid item xs={12}>
                    <h3 className="center warning-dark">
                      Invalid email or password, or your session has expired.
                    </h3>
                  </Grid>
                )}

                <Grid
                  container
                  item
                  xs={6}
                  alignContent="flex-end"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => signIn()}
                    disabled={
                      user.username!.length < 5 || user.password!.length < 5
                    }
                  >
                    Sign in
                  </Button>
                </Grid>
                <Grid container item xs={6} alignContent="flex-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={initOAuthSignIn}
                    disabled={
                      user.username!.length > 3 && user.password!.length > 3
                    }
                  >
                    <GoogleIcon />
                    Sign In
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <h5 className="center">
                    <Link to="/sign-up">Sign Up? </Link> |
                    <Link to="/forgot-password"> Forgot password? </Link> |
                    <Link to="/forgot-password/1"> I have code </Link> |
                    <Link to="/sign-up/2"> Confirm my email </Link>
                  </h5>
                </Grid>
              </Grid>
            )}
          </div>
        </Container>
      </ThemeCard>
    </div>
  );
};

export default SignIn;

/**
 * 
 * 
 * 
 

 CognitoUser {username: "sinotar", pool: CognitoUserPool, Session: null, client: Client, signInUserSession: null, …}

 */

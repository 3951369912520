"use client";

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import "./style.css";
import {
  Chip,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import NewReleasesIcon from "@mui/icons-material/NewReleases";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import CartSummary from "./cart-summary";
import PaypalSummary from "./paypal-summary";
import { LocalShipping } from "@mui/icons-material";

/**
 * This is Cart component view (different from Cart page view)
 * CartSummary - implement summary view for a Cart (with list of cart items and total # of items and total $price)
 * @param props
 * @returns
 */
const Order = (props: any) => {
  const router = useNavigate();

  const [order, setOrder] = useState(props.order as any);

  useEffect(() => {}, []);

  return (
    <Card className="order-card">
      <Grid
        container
        direction="row"
        columnSpacing={4}
        rowSpacing={8}
        justifyContent="center"
        justifyItems="center"
        alignItems="stretch"
        textAlign="center"
        alignContent="center"
      >
        <Grid item xs={12} sm={6} md={6} lg={6} textAlign="left">
          <Chip label={`ID: ${order.id}`} variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} textAlign="right">
          <Chip label={`Date: ${order.date}`} variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
          <InputLabel id="status-input-label">Order Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={order.status}
            size="small"
            label="Order Status"
            onChange={(e: any) => {
              setOrder({ ...order, status: e.target.value });
            }}
          >
            <MenuItem value="NEW" selected={order.status == "NEW"}>
              <NewReleasesIcon color="warning" /> NEW
            </MenuItem>
            <MenuItem
              value="PROCESSING"
              selected={order.status == "PROCESSING"}
            >
              <DirectionsRunIcon color="warning" /> PROCESSING
            </MenuItem>
            <MenuItem value="SHIPPED" selected={order.status == "SHIPPED"}>
              <LocalShippingIcon color="info" /> SHIPPED
            </MenuItem>
            <MenuItem value="DELIVERED" selected={order.status == "DELIVERED"}>
              <NoCrashIcon color="info" /> DELIVERED
            </MenuItem>
            <MenuItem value="COMPLETED" selected={order.status == "COMPLETED"}>
              <CheckCircleIcon color="info" /> COMPLETED
            </MenuItem>
            <MenuItem value="CANCELLED" selected={order.status == "CANCELLED"}>
              <CancelIcon color="info" /> CANCELLED
            </MenuItem>
          </Select>
        </Grid>

        {order.cart != null && (
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <CartSummary cart={order.cart} />
          </Grid>
        )}

        {order.pay_option == "paypal" && order.paypal_order_data != null && (
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <PaypalSummary paypal={order.paypal_order_data} />
          </Grid>
        )}

        {order.pay_option == "pay_upon_delivery" && (
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <h3 className="center">[Note] Payment is to be made upon delivery. </h3>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Order;

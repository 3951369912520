import { useEffect, useRef, useState } from "react";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Accordion, AccordionDetails, AccordionSummary, Container, Fade, Grid } from "@mui/material/";
import "../custom.d.ts";
import "./style.scss";

import Banner from "../components/banner/banner";
import VideoThemeCard from "../components/card/video-theme-card";

import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SpeedIcon from "@mui/icons-material/Speed";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; 

import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";
import ThemeCard from "../components/card/theme-card";

const GetStarted = (props: any) => {

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const [webContent, setWebContent] = useState({} as DWebContent);

  useEffect(() => {
    document.title = "BSG:Get Started";

    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };
    BSGAPI.getWebContent("get-started").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    window.scrollTo(0, 0);

    //myApp.user?.setLinkedInFirstName(myApp.user, "Julian");
  }, []);

  return (
    <div>

<ThemeCard
        backgroundImg="https://my-bsg-asset.s3.amazonaws.com/image/pic_dashboard.jpg"
        title=""
        description=""
        minHeight={400}
        opacity={0.4}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignContent="flex-start"
          >
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
                      <div ref={myRefWe}>
      <Fade in={triggerWe} timeout={4000}>
<h1 className="warning-light center">{webContent.h1?.title}</h1>
</Fade>
</div>
           </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >

<h2 className="primary-light center">{webContent.h1?.description}</h2>
                <h3 className="primary-light center">{webContent.h1?.content}</h3>
                                </Grid>

          </Grid>
        </Container>
      </ThemeCard>
      
      <div>
{webContent.content && (
        <div className="section-white">
          <Container maxWidth="lg">
            <div>
              <h2>{webContent.h2?.title}</h2>
              <h3>{webContent.h2?.description}</h3>
            </div>
            {webContent.content?.map((content, index) => (
              <Accordion key={`faq-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h2 className="secondary-light">{content.title}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h3>${content.content}</h3>`,
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </div>
      )}

</div>

    </div>
  );
};

export default GetStarted;

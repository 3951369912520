import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Button, Container, Fade, Grid, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DNameValue, DProfile, DUser, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import IconCard from "../components/card/icon-card";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import NameValueCard from "../components/card/name-value-card";
import BTextField from "../components/form/text-field";
import BSelectList from "../components/form/select-list";
import { BSGAPI } from "../auth/bsg-api";
import { BSGAPI2 } from "../auth/bsg-api2";
import BFormInput from "../components/form/form-input";
import Loading from "../components/loading/loading";
import LocationCityIcon from "@mui/icons-material/LocationCity";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import HubIcon from "@mui/icons-material/Hub";
import { PublicAPI } from "../auth/public-api";
import { AdminAPI2 } from "../auth/admin-api2";

const AdminUserProfile = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { email } = useParams();

  const [webContent, setWebContent] = useState({} as DWebContent);
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  //const [gdrive, setGdrive] = useState("");
  //let [profile, setProfile] = useState({} as DProfile);
  const [profile, setProfile] = useState({} as any);

  let [attributes, setAttributes] = useState([] as DNameValue[]);

  let [contactCardDisabled, setContactCardDisabled] = useState(true);
  let [payCardDisabled, setPayCardDisabled] = useState(true);
  let [professionCardDisabled, setProfessionCardDisabled] = useState(true);
  let [locationCardDisabled, setLocationCardDisabled] = useState(true);

  let [loading, setLoading] = useState(true);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const [loadingFolderSetup, setLoadingFolderSetup] = useState(false);
  const [showErrorFolderSetup, setShowErrorFolderSetup] = useState(false);
  const [errorFolderSetup, setErrorFolderSetup] = useState("");
  const [folderSetupButtonDisabled, setFolderSetupButtonDisabled] =
    useState(false);

  const [states, setStates] = useState([]);

  const updateProfile = () => {
    setLoading(true);
    AdminAPI2.adminUpdateProfile(context.user.cognitoCredentials, profile).then(
      (json) => {
        //console.log(json);
        //setData(data);
        if (json.status == "ok") {
          //console.log("OK ...");
          //setObjects(json.data);  // for array type testing
          //setData(json.data); // for json type testing
          setLoading(false);
          setContactCardDisabled(true);
        } else {
          //console.log("BAD ..." + json.status);
          setError(json.status);
          setShowError(true);
        }
      }
    );
  };

  const setupFolder = () => {
    setFolderSetupButtonDisabled(true);
    setLoadingFolderSetup(true);

    AdminAPI2.adminSetupFolder(
      context.user.cognitoCredentials,
      email as string
    ).then((json) => {
      //console.log(json);
      //setData(data);
      if (json.status == "ok") {
        //console.log("OK ...");
        //setObjects(json.data);  // for array type testing
        //setData(json.data); // for json type testing
        setLoadingFolderSetup(false);
        setProfile({ ...profile, gdrive: json.data });

        setContactCardDisabled(true);
      } else {
        //console.log("BAD ..." + json.status);
        setErrorFolderSetup(json.status);
        setShowErrorFolderSetup(true);
        setFolderSetupButtonDisabled(false);
      }
    });
  };

  useEffect(() => {
    document.title = "BSG Partner:My Profile";

    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        setLoading(true);
        AdminAPI2.adminGetProfile(
          context.user.cognitoCredentials,
          email as string
        ).then((json) => {
          if (json.status == "ok") {
            //console.log("JSON: " + JSON.stringify(json));
            setProfile(json.data as any);
            /***
            if(profile["email"] == null) {
              setProfile({...profile, email: email}); 
              setError("Profile does not exist");
              setShowError(true);
              setInitate(true); 
            }
               */
            setLoading(false);
            /** 
          } else if (json.status == "token-expired") {
            setTimeout(() => {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            }, 3000);
**/
            //profile["email"] = email;
            //setProfile({...profile, email: email});
          } else {
            //console.log("Error: " + JSON.stringify(json));
          }
        });

        forceUpdate();
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/admin/user-profile`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    BSGAPI.getWebContent("profile").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    PublicAPI.get("states")
      .then((json) => {
        //console.log("States: " + JSON.stringify(json));
        setStates(json.states);
      })
      .catch((e: any) => {
        //console.log("States Error: " + e);
      });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div>
        {loading ? (
          <div>
            <Loading show={loading} />
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignContent="center"
            >
              {showError && (
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={() => {
                    setShowError(false);
                  }}
                  severity="error"
                >
                  {error}
                </MuiAlert>
              )}
            </Grid>
          </div>
        ) : (
          <div className="section-white">
            <Container maxWidth="lg">
              <Grid
                container
                rowSpacing={8}
                columnSpacing={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                alignContent="center"
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <NameValueCard
                    key="info-contact"
                    icon={
                      <PermPhoneMsgIcon color="info" style={{ fontSize: 64 }} />
                    }
                    title="How We Contact You"
                    description=""
                    onClick={() => {
                      updateProfile();
                      setContactCardDisabled(true);
                    }}
                    onClickText="Save"
                    onClickDisabled={contactCardDisabled}
                  >
                    <BFormInput
                      label="name"
                      editMode={false}
                      canEdit={false}
                      name="name"
                      value={`${profile["fname"]} ${profile["lname"]}`}
                    ></BFormInput>

                    <BFormInput
                      label="Mobile/SMS"
                      editMode={false}
                      name="mobile"
                      value={profile["mobile"]}
                      onChange={(value: any) => {
                        setProfile({ ...profile, mobile: value });
                        setContactCardDisabled(false);
                      }}
                    ></BFormInput>
                    <BFormInput
                      label="WeChat"
                      editMode={false}
                      name="wechat"
                      value={profile["wechat"]}
                      onChange={(value: any) => {
                        setProfile({ ...profile, wechat: value });
                        setContactCardDisabled(false);
                      }}
                    ></BFormInput>
                  </NameValueCard>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <NameValueCard
                    key="info-payment"
                    icon={
                      <MonetizationOnIcon
                        color="info"
                        style={{ fontSize: 64 }}
                      />
                    }
                    title="How We Pay You"
                    description=""
                    onClick={() => {
                      updateProfile();
                      setContactCardDisabled(true);
                    }}
                    onClickText="Save"
                    onClickDisabled={payCardDisabled}
                  >
                    <h1></h1>
                    <BSelectList
                      label="Payment Method"
                      editMode={false}
                      name="pay_method"
                      value={profile["pay_method"]}
                      list={["Venmo", "Paypal", "Check", "Zelle"]}
                      onChange={(value: any) => {
                        //setAttribute("pay_method", e.target.value);
                        setPayCardDisabled(false);
                        setProfile({ ...profile, pay_method: value });
                      }}
                    ></BSelectList>
                    <BFormInput
                      label="Payment Method Info"
                      tip="Provide account info for this payment method. For check, please provide mailing address. "
                      editMode={false}
                      name="pay_method_detail"
                      value={profile["pay_method_detail"]}
                      onChange={(value: any) => {
                        //setAttribute("pay_method_detail", e.target.value);
                        setPayCardDisabled(false);
                        setProfile({ ...profile, pay_method_detail: value });
                      }}
                    ></BFormInput>
                  </NameValueCard>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  {profile["gdrive"] !== null &&
                  profile["gdrive"] !== "" &&
                  profile["gdrive"] !== undefined ? (
                    <IconCard
                      key="info-gdrive"
                      icon={
                        <AccountBalanceIcon
                          color="info"
                          style={{ fontSize: 64 }}
                        />
                      }
                      title="G-Folder"
                      description="Google Drive Folder for storing, collaborating"
                      onClick={() => {
                        window.open(
                          `https://drive.google.com/drive/u/1/folders/${profile["gdrive"]}`,
                          "_blank"
                        );
                      }}
                      onClickText="Open G-Drive"
                    ></IconCard>
                  ) : (
                    <IconCard
                      key="info-gdrive"
                      color="warning"
                      icon={
                        <AccountBalanceIcon
                          color="info"
                          style={{ fontSize: 64 }}
                        />
                      }
                      title="G-Folder"
                      description="Google Drive Folder for storing, collaborating"
                      onClick={() => {
                        // call method to set up gdrive
                        setupFolder();
                      }}
                      onClickText="Set Up Now"
                      onClickDisabled={folderSetupButtonDisabled}
                    >
                      {loadingFolderSetup ? (
                        <div>
                          <Loading show={loadingFolderSetup} />

                          <h3>
                            It will take a few seconds while we are setting up
                            folder. Please be patient and wait ...
                          </h3>
                        </div>
                      ) : (
                        <>
                          {showErrorFolderSetup && (
                            <MuiAlert
                              elevation={6}
                              variant="filled"
                              onClose={() => {
                                setShowErrorFolderSetup(false);
                              }}
                              severity="error"
                            >
                              {errorFolderSetup}
                            </MuiAlert>
                          )}
                        </>
                      )}
                    </IconCard>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <IconCard
                    key="info-tax"
                    icon={
                      <AccountBalanceIcon
                        color="info"
                        style={{ fontSize: 64 }}
                      />
                    }
                    title="Tax"
                    description="1099 Form will be sent to you at the beginning of year when income is more than $600"
                    onClick={() => {
                      window.open(
                        `https://drive.google.com/drive/u/1/folders/${profile["gdrive"]}`,
                        "_blank"
                      );
                    }}
                    onClickText="Open G-Drive"
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <NameValueCard
                    key="info-contact"
                    icon={<HubIcon color="info" style={{ fontSize: 64 }} />}
                    title="Professional Network"
                    description=""
                    onClick={() => {
                      updateProfile();
                      setProfessionCardDisabled(true);
                    }}
                    onClickText="Save"
                    onClickDisabled={professionCardDisabled}
                  >
                    <BFormInput
                      label="LinkedIn"
                      editMode={false}
                      name="linkedin"
                      value={profile["linkedin"]}
                      onChange={(value: any) => {
                        setProfile({ ...profile, linkedin: value });
                        setProfessionCardDisabled(false);
                      }}
                    ></BFormInput>
                    <BFormInput
                      label="Resume"
                      editMode={false}
                      name="resume"
                      value={profile["resume"]}
                      onChange={(value: any) => {
                        setProfile({ ...profile, resume: value });
                        setProfessionCardDisabled(false);
                      }}
                    ></BFormInput>
                  </NameValueCard>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <NameValueCard
                    key="info-contact"
                    icon={
                      <LocationCityIcon color="info" style={{ fontSize: 64 }} />
                    }
                    title="My City"
                    description=""
                    onClick={() => {
                      updateProfile();
                      setLocationCardDisabled(true);
                    }}
                    onClickText="Save"
                    onClickDisabled={locationCardDisabled}
                  >
                    <BFormInput
                      label="city"
                      editMode={false}
                      name="city"
                      value={profile["city"]}
                      onChange={(value: any) => {
                        setProfile({ ...profile, city: value });
                        setLocationCardDisabled(false);
                      }}
                    ></BFormInput>
                    <BSelectList
                      label="State"
                      editMode={false}
                      name="state"
                      value={profile["state"]}
                      keyValueList={states}
                      onChange={(value: any) => {
                        setLocationCardDisabled(false);
                        //setProfile({...profile, state: e.target.value});
                        setProfile({ ...profile, state: value });
                      }}
                    ></BSelectList>
                  </NameValueCard>
                </Grid>
              </Grid>
            </Container>

<h1></h1>
            <div className="section-white">
            <Container maxWidth="lg">
            <Grid
                container
                rowSpacing={8}
                columnSpacing={2}
                rowGap={2}
                columnGap={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                alignContent="center"
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  justifyContent="center"
                  alignContent="center"
                >
              <NameValueCard
                key="info-contact"
                icon={
                  <PermPhoneMsgIcon color="info" style={{ fontSize: 64 }} />
                }
                title="Profile"
                description=""
                onClick={() => {
                  updateProfile();
                  setContactCardDisabled(true);
                }}
                onClickText="Save"
                onClickDisabled={contactCardDisabled}
              >
                {Object.keys(profile).map((key: any) => {
                  return (
                    <BFormInput
                      label={key}
                      editMode={false}
                      name={key}
                      value={profile[key]}
                      onChange={(value: any) => {
                        //console.log("onChange is called ...: " + key);
                        profile[key] = value;
                        setProfile({ ...profile, [key]: value });

                        setContactCardDisabled(false);
                      }}
                    ></BFormInput>
                  );
                })}
              </NameValueCard>
              </Grid>
              </Grid>
            </Container>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminUserProfile;

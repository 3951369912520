import { Container } from "@mui/material";
import styles from "./layout.module.css";
import Cart from "./components/cart";
import { DWZStore } from "./api/dwz-store";
/**
 * This is page view for a cart (details)
 * @returns
 */
export default function ViewCart() {
  return (
    <Container maxWidth="md">
      <div className={styles.center}>
        <h1>{ DWZStore.STORE_NAME} - My Shopping Bag</h1>
      </div>
      <Cart detail={true} />
    </Container>
  );
}

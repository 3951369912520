import {
  Alert,
  Card,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import styles from "./layout.module.css";
import PaypalButton from "./components/paypal-button";
import { useEffect, useState } from "react";
import { DWZStore } from "./api/dwz-store";
import CartSummary from "./components/cart-summary";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { DCart, DCartItem } from "./model";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import PayUponDeliveryButton from "./components/pay-upon-delivery-button";

export default function BuyNow() {
  // location is how the object is passed from another component (Product).
  const location = useLocation();
  const item = location.state as DCartItem;

  const [payOption, setPayOption] = useState("paypal");

  // use query to pass product id
  // const [searchParams, setSearchPrams] = useSearchParams();
  // const id = searchParams.get("id") != null ? (searchParams.get("id") as string) : "";

  const [cart, setCart] = useState({
    items: item != null ? [item] : [],
  } as DCart);

  useEffect(() => {
    //setCart(DWZStore.getCart());
    //setTotalItems(DWZStore.countTotalItems(cart));
  }, []);

  return (
    <Container maxWidth="md">
      <div className={styles.center}>
        <h1>{DWZStore.STORE_NAME} - Buy Now</h1>
      </div>

      <Card>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          spacing={2}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            {cart != null ? (
              <CartSummary cart={cart} expanded={true} />
            ) : (
              <h3></h3>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <div>
              {cart != null && cart.items.length > 0 ? (
                <>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="paypal"
                      control={<Radio />}
                      checked={payOption == 'paypal'}
                      label="Pay by Paypal/Credit Card"
                      onChange={(e: any) => {setPayOption(e.target.value);}}
                    />
                    {cart.items[0].product.pay_options.indexOf(
                      "pay_upon_delivery"
                    ) > -1 && (
                      <FormControlLabel
                        value="pay_upon_delivery"
                        control={<Radio />}
                        checked={payOption == 'pay_upon_delivery'}
                        label="Pay Upon Delivery (Cash or Check)"
                        onChange={(e: any) => {setPayOption(e.target.value);}}
                      />
                    )}
                  </RadioGroup>

                  { payOption == "paypal" && <PaypalButton cart={cart} /> }
                  { payOption == "pay_upon_delivery" && <PayUponDeliveryButton cart={cart} /> }
                </>
              ) : (
                <Alert
                  icon={<NearbyErrorIcon fontSize="large" />}
                  severity="warning"
                  sx={{
                    marginTop: "40px",
                    marginBottom: "40px",
                    fontSize: "18px",
                  }}
                >
                  Nothing to buy. Please contact us if you believe this is an
                  error.
                </Alert>
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

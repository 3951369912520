"use client";

import { DCognitoCredentials } from "../../model";
import {
  DCart,
  DCartItem,
  DChoiceOption,
  DPriceOption,
  DProduct,
} from "../model";

export class DWZStore {

  static STORE_NAME: string = "BSG Store";
  static STORE_SLOGAN: string = "BSG Store for innovating eCommerce solutions by offering BSG community services for our community."; 

  /**
   * list products
   */
    static listProducts() {
      return new Promise<any>((resolve, reject) => {
        const options = {
          method: "POST",
          headers: {
            "Cache-Control": "No-Cache",
            "Content-Type": "application/json",
            //Authorization: `Bearer ${credentials.AccessToken}`,
          },
          //body: JSON.stringify({ user: user, params: {} }),
        };
        fetch("/api2/list-products", options)
          .then((response) => response.json())
          .then((json) => {
            resolve(json); // let client to deal with error/exception
          })
          .catch((err) => {
            //console.log("Error: " + err);
            reject(err);
          });
      });
    }

  /**
   * Store APIs
   */
  static getProduct2(id: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          //Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ id: id }),
      };
      fetch("/api2/get-product", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getCart() {
    return new Promise<any>((resolve, reject) => {
      var str =
        typeof window !== "undefined"
          ? localStorage.getItem("DWZ_STORE_CART")
          : null;

      //console.log("JSON-Cart: " + str);
      var cart = null;
      try {
        cart = JSON.parse(str as string);
      } catch (e) {
        cart = { items: [] } as DCart;
      } finally {
        // additional check to make sure cart is valid. If not, reset cart to be empty.
        // This is important to make sure the cart object is compatible with new implemention.
        // TODO - may introduce cart.version in the future for compatibility check.
        if (cart == null || cart.items == undefined || cart.items == null)
          cart = { items: [] } as DCart;
        resolve(cart);
      }
    });
  }

  static clearCart() {
    return new Promise<any>((resolve, reject) => {
      if (typeof window !== "undefined")
        localStorage.setItem(
          "DWZ_STORE_CART",
          JSON.stringify({ items: [] } as DCart)
        );
      resolve({ items: [] });
    });
  }
  /**
   * Update Cart - including rules to add, delete, and update based on item quantity
   * @param cartItem
   */
  static addToCart(cartItem: DCartItem) {
    return new Promise<any>((resolve, reject) => {
      DWZStore.getCart().then((cart) => {
        //cart.items.push(cartItem);
        var exists = false;
        for (var i = 0; i < cart.items.length; i++) {
          var item = cart.items[i];
          if (DWZStore.isIdentical(item, cartItem)) {
            exists = true;
            cart.items[i].quantity += cartItem.quantity;
            if (cart.items[i].quantity <= 0) {
              // remove from cart if quantity is 0 or lower.
              cart.items.splice(i, 1);
            }
          }
        }
        if (!exists) {
          cart.items.push(cartItem);
        }

        //console.log("CART: " + JSON.stringify(cart));
        if (typeof window !== "undefined")
          localStorage.setItem("DWZ_STORE_CART", JSON.stringify(cart));

        resolve(cart);
      });
    });
  }

  static removeFromCart(cartItem: DCartItem) {
    return new Promise<any>((resolve, reject) => {
      DWZStore.getCart().then((cart) => {
        //cart.items.push(cartItem);
        var exists = false;
        for (var i = 0; i < cart.items.length; i++) {
          var item = cart.items[i];
          if (DWZStore.isIdentical(item, cartItem)) {
            cart.items.splice(i, 1);
          }
        }
        if (typeof window !== "undefined")
          localStorage.setItem("DWZ_STORE_CART", JSON.stringify(cart));

        resolve(cart);
      });
    });
  }

  static countTotalItems(cart: DCart) {
    //console.log("Cart: " + JSON.stringify(cart));
    var total: number = 0;
    for (var i = 0; cart != null && i < cart.items.length; i++) {
      total += cart.items[i].quantity as number;
    }
    return total as number;
  }

  static countTotalAmount(cart: DCart) {
    var total = 0.0;
    for (var i = 0; cart != null && i < cart.items.length; i++) {
      total += (cart.items[i].quantity *
        cart.items[i].price_option.price) as number;
    }
    return total;
  }

  // To test if the two cart items are the same.
  static isIdentical(a: DCartItem, b: DCartItem) {
    //console.log("A-item: " + JSON.stringify(a));
    //console.log("B-item: " + JSON.stringify(b));

    if (a.product.id != b.product.id) return false;

    if (a.price_option?.option != b.price_option?.option) return false;

    // same choice option length
    if (a.choice_options?.length != b.choice_options?.length) return false;

    // loop through choice options and compare each one
    for (
      var i = 0;
      a.choice_options != null &&
      a.choice_options.length >= 0 &&
      b.choice_options != null &&
      i < a.choice_options?.length;
      i++
    ) {
      // let's find a match from b for a given option.
      var option = b.choice_options.find(
        (o) =>
          o.name == a.choice_options[i].name &&
          o.value == a.choice_options[i].value
      );
      if (option == null) return false; // no match find.
    }
    return true;
  }

    // get products
    static getProducts(query: any) {
      return new Promise<any>((resolve, reject) => {
        const options = {
          method: "POST",
          headers: {
            "Cache-Control": "No-Cache",
            "Content-Type": "application/json",
            //Authorization: `Bearer ${credentials.AccessToken}`,
          },
          body: JSON.stringify({
            query: query
          }),
        };
        fetch("/api2/get-products", options)
          .then((response) => response.json())
          .then((json) => {
            resolve(json); // let client to deal with error/exception
          })
          .catch((err) => {
            //console.log("Error: " + err);
            reject(err);
          });
      });
    }

    static getProduct(id: string) {
      return new Promise<any>((resolve, reject) => {
        const options = {
          method: "POST",
          headers: {
            "Cache-Control": "No-Cache",
            "Content-Type": "application/json",
            //Authorization: `Bearer ${credentials.AccessToken}`,
          },
          body: JSON.stringify({
            id: id
          }),
        };
        fetch("/api2/get-product", options)
          .then((response) => response.json())
          .then((json) => {
            resolve(json); // let client to deal with error/exception
          })
          .catch((err) => {
            //console.log("Error: " + err);
            reject(err);
          });
      });
    }

    static updateOrder(order: any) {
      return new Promise<any>((resolve, reject) => {
        const options = {
          method: "POST",
          headers: {
            "Cache-Control": "No-Cache",
            "Content-Type": "application/json",
            //Authorization: `Bearer ${credentials.AccessToken}`,
          },
          body: JSON.stringify({
            order: order
          }),
        };
        fetch("/api2/update-order", options)
          .then((response) => response.json())
          .then((json) => {
            resolve(json); // let client to deal with error/exception
          })
          .catch((err) => {
            //console.log("Error: " + err);
            reject(err);
          });
      });
    }

}

import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import styles from "../layout.module.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import { DWZStoreAdmin } from "../api/dwz-store-admin";
import { Container, Grid } from "@mui/material";
import { DWZStore } from "../api/dwz-store";
import Loading from "../../components/loading/loading";
import Order from "../components/order";

/**
 * This is Cart component view (different from Cart page view)
 * @param props
 * @returns
 */
const Orders = (props: any) => {


  const navigate = useNavigate();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [orders, setOrders] = useState([] as any[])
  const [loadingOrders, setLoadingOrders] = useState(false); 

  useEffect(() => { 


    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

        // get company (as Client manager)
        setLoadingOrders(true); 
        DWZStoreAdmin.adminGetOrders(
          context.user.cognitoCredentials,
          {}
        ).then((response: any) => {
          //setOpportunityTotal(parseInt(response.data.total));
          //console.log("response: " + JSON.stringify(response));

          if (response.status == "ok") {
            setOrders(response.data as any[]);
          } else if (response.status == "token-expired") {
            navigate("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
          }
          setLoadingOrders(false);
        });

        
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        navigate("/sign-in");
      }
    });


    return () => {
    };
  }, []);


  return (
    <>
      <Container maxWidth="xl">
        <div className={styles.center}>
          <h1>{ DWZStore.STORE_NAME}</h1>
        </div>
        <div className={styles.center}>
          <h3 className="center">{ DWZStore.STORE_SLOGAN}</h3>
        </div>
      </Container>


        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={4}
            columnSpacing={4}
            rowGap={4}
            columnGap={4}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid container item lg={12} justifyContent="center">
              {loadingOrders ? (
                <Loading show={loadingOrders} />
              ) : (
<>
            {orders?.map((obj: any, index: number) => {
              return (
                <Grid
                  container
                  item
                  lg={12}
                  justifyContent="center"
                  key={`grid-${index}`}
                >

<Order order={obj} />

                </Grid>
              );
            })}</>

          )}
          </Grid>
          </Grid>
        </Container>
    </>
  );
};

export default Orders;

import { Card, CircularProgress, Container, Grid } from "@mui/material";
import styles from "./layout.module.css";
import { useContext, useEffect, useState } from "react";
import { DCartItem, DChoiceOption, DChoiceOptions, DProduct } from "./model";
import ThemeCard from "../components/card/theme-card";
import ProductCard from "./components/product-card";
import Cart from "./components/cart";
import { DWZStore } from "./api/dwz-store";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../context/user-context";

export default function Store() {

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  //console.log("[React NODE] " + process.env.NODE_ENV); 
  const navigate = useNavigate();

  const [products, setProducts] = useState([] as DProduct[]);

  const [loadingProducts, setLoadingProducts] = useState(true);

  function getProducts(query: any) {
    DWZStore.getProducts(query).then((json) => {
      setProducts(json.data as DProduct[]);
      setLoadingProducts(false);
    });
  }

  const getSampleChoiceOptions = (allChoiceOptions: DChoiceOptions[]) => {
    var choiceOptions = [] as DChoiceOption[];
    for (
      var i = 0;
      allChoiceOptions != null && i < allChoiceOptions.length;
      i++
    ) {
      choiceOptions.push({
        name: allChoiceOptions[i].name,
        value: allChoiceOptions[i].values[0],
      });
    }
    return choiceOptions;
  };

  const addToCart2 = (product: DProduct) => {
    // for testing addToCart
    const item = {
      product: product,
      quantity: 1,
      price_option:
        product.price_options != undefined && product.price_options.length > 0
          ? product.price_options[0]
          : null,

      choice_options: getSampleChoiceOptions(product.choice_options),
    } as DCartItem;

    DWZStore.addToCart(item).then((cart) => {
      const customEvent = new CustomEvent("store:cart:updated", {
        detail: { cart: cart },
      });
      document.dispatchEvent(customEvent);
    });
  };

  const addToCart = (item: DCartItem) => {
    DWZStore.addToCart(item).then((cart) => {
      const customEvent = new CustomEvent("store:cart:updated", {
        detail: { cart: cart },
      });
      document.dispatchEvent(customEvent);
    });
  };

  // TODO
  const buyNow = (item: DCartItem) => {
    //const customEvent = new CustomEvent("store:cart:updated", {
    //  detail: { product: product },
    //});
    //document.dispatchEvent(customEvent);
    navigate("/store/buy", { state: item});
  };

  useEffect(() => {
    
    getProducts({category: "bsg"});

  }, []);

  return (
    <>
      <Cart detail={false} />
      <Container maxWidth="xl">
        <div className={styles.center}>
          <h1>{ DWZStore.STORE_NAME}</h1>
        </div>
        <div className={styles.center}>
          <h3 className="center">{ DWZStore.STORE_SLOGAN}</h3>
        </div>
      </Container>

      <ThemeCard
        backgroundImg="https://dwz-data.s3.amazonaws.com/assets/images/york_01.jpg"
        title=""
        description=""
        minHeight={1800}
        opacity={0.6}
        caption=""
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={8}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid container item lg={12} justifyContent="center">
              {loadingProducts ? (
                <CircularProgress color="secondary" sx={{ fontSize: 80 }} />
              ) : (
                ""
              )}
            </Grid>
            {products?.map((obj, index) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  justifyContent="center"
                  key={`grid-${index}`}
                >
                  <ProductCard
                    key={`prod-div-${index}`}
                    product={obj}
                    onAddToCart={(item: DCartItem) => {
                      addToCart(item);
                    }}
                    onBuyNow={(item: DCartItem) => {
                      buyNow(item);
                    }}
                    showRetailPrice={true}
                    showPriceOptions={true}
                    showQuantityOptions={true}
                    showAddToCart={true}
                    showBuyNow={true}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </ThemeCard>
    </>
  );
}

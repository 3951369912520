import {
  Box,
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Tooltip,
  useScrollTrigger,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CancelIcon from '@mui/icons-material/Cancel';

import "./style.scss";
import { useEffect, useRef, useState } from "react";
import { flexbox } from "@mui/system";

export interface ITextField {
  label?: string;
  tip?: string;
  editMode?: boolean;
  canEdit?: boolean; 
  name?: string;
  value?: string | number;
  multiline?: boolean;
  onChange?: any;
  onCommit?: any;
}

const BTextField = ({
  label,
  tip,
  editMode,
  canEdit = true, 
  name,
  value,
  multiline,
  onChange,
  onCommit,
}: ITextField) => {

  const [myEditMode, setMyEditMode] = useState(editMode);

  const [inputFocused, setInputFocused] = useState(false); 

  const [newValue, setNewValue] = useState(value);


  const inputRef = useRef<HTMLInputElement>();
  
  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  useEffect(() => {
  }, []);

  return (
    <div style={{ width: "100%" }} onBlur={() => {
      //console.log("onBlur ..."); 
      // only set edit mode false when input field is not focused. 
      //if(!inputFocused) {
      //  console.log("set edit mode false"); 
      //  setMyEditMode(false);
      //}
    }}>
      <Box sx={{ width: "100%" }}>
        <h3>
          {label}
          {tip && (
            <Tooltip title={tip} arrow>
              <HelpOutlineIcon style={{ fontSize: 20, padding: 2 }} />
            </Tooltip>
          )}
        </h3>
      </Box>

      {myEditMode ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Box sx={{ flexGrow: 1, justifyContent: "flex-stretch" }}>
            <TextField
            sx={{width: "100%"}}
              inputRef={setTextInputRef} 
              required
              multiline={multiline}
              variant="outlined"
              color="secondary"
              id="name"
              label={label}
              value={newValue}
              onChange={(e: any) => {
                  //onChange(e.target.value);
                  setNewValue(e.target.value);
                }
              }
              onFocus={() => { 
                //setInputFocused(true);
              }}
              onBlur={()=> { 
                //setInputFocused(false);
                //console.log("on blue fired ..."); 
                setMyEditMode(false); 
                setNewValue(newValue); 
                onChange(newValue);
              }}
            />
          </Box>
          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                if (onCommit) {
                  onCommit();
                }
                //setNewValue(newValue);
                //console.log("on click fired ..."); 
                onChange(newValue); 
                setMyEditMode(false);
              }}
            >
              <CheckCircleIcon />
            </IconButton>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Box
            flexGrow={1}
            sx={{ border: "1px solid silver", p: 1, borderRadius: "15px" }}
          >
            <h3 className="left warning-dark">{newValue ? newValue : "(not set)"}</h3>
          </Box>
          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              disabled={!canEdit}
              onClick={() => {
                setMyEditMode(true);
                //console.log("clicked ....");
                 if(inputRef.current) {
                  //console.log("trying to focus ...");
                  inputRef.current.focus();
                 
                  }
                    //setInputFocused(true);

              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </div>
      )}
    </div>
  );
};

export default BTextField;

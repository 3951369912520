"use client";

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Avatar,
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Fab,
  Fade,
  Grid,
  Hidden,
  Icon,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Zoom,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";
import { DCart, DCartItem, DProduct } from "../model";
import {
  ArrowBack,
  DeleteOutline,
  Handshake,
  Payment,
  ShoppingBag,
  ShoppingBasket,
  ThumbUp,
  TouchAppRounded,
} from "@mui/icons-material";
import { DWZStore } from "../api/dwz-store";
import { deepOrange } from "@mui/material/colors";
import ProductCard from "./product-card";
import CartItem from "./cart-item";

/**
 * This is Cart component view (different from Cart page view)
 * @param props
 * @returns
 */
const Cart = (props: any) => {
  const router = useNavigate();

  const [cart, setCart] = useState(null as any);
  const [animate, setAnimate] = React.useState(false);

  const [totalItems, setTotalItems] = useState(0 as number);

  const [totalAmount, setTotalAmount] = useState(0.0 as number);

  const [detail, setDetail] = useState(props.detail);

  const [cartUpdated, setCartUpdated] = useState(1); // used to trigger UI update for cart.

  const [backdrop, setBackdrop] = useState(false);

  const test = () => {
    /**
       localStorage.setItem(
              BSGAuth.MYBSG_PERSON,
              JSON.stringify(json.person)
            );
            localStorage.removeItem(BSGAuth.MYBSG_PERSON);
        item = localStroage.getItem(KEY);
        json = JSON.parse(item);   
      **/
  };

  const loadCart = () => {
    DWZStore.getCart().then((cart) => {
      setCart(cart);
    });
  };

  useEffect(() => {
    document.addEventListener("store:cart:updated", function (e: any) {
      // when tracker updated, we refresh as well.
      //console.log("Got event: cart updated: " + JSON.stringify(e.detail.cart));
      //loadCart();
      setCart(e.detail.cart);
    });
    return () => {
      document.removeEventListener("store:cart:updated", () => {});
    };
  }, []);

  useEffect(() => {
    loadCart();
    //setCart(DWZStore.getCart());
    //setTotalItems(DWZStore.countTotalItems(cart));
  }, [cartUpdated]);

  useEffect(() => {
    var totalItems = DWZStore.countTotalItems(cart) as number;
    var totalAmount = DWZStore.countTotalAmount(cart) as number;
    //console.log("Total Items: " + totalItems);
    setTotalItems(totalItems);
    setTotalAmount(totalAmount); // xx.xx format
    setAnimate(true);
    setBackdrop(true);
    let timer1 = setTimeout(() => {
      setBackdrop(false);
    }, 1000);
    let timer2 = setTimeout(() => {
      setAnimate(false);
    }, 3000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [cart]);

  return (
    <div>
      {" "}
      <Backdrop
                sx={(theme) => ({
                  color: "#fff",
                  zIndex: theme.zIndex.drawer + 1,
                })}
                open={backdrop}
                onClick={() => {
                  setBackdrop(false);
                }}
              >
                <h3 className="center"><CircularProgress color="inherit" /><br/>Working on it ...</h3>
              </Backdrop>
      {detail && cart != null ? (
        <Container
          maxWidth="lg"
          sx={{ width: "100%", bgcolor: "white", paddingBottom: 4 }}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            columnSpacing={4}
            rowGap={4}
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid container item lg={12} justifyContent="center" 
            alignItems="stretch" textAlign="center">
              <h3 className="center">Please review your shopping bag</h3>
            </Grid>
            {cart.items?.map((obj: DCartItem, index: number) => {
              return (
                <Grid
                container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  alignItems="stretch"
                  justifyContent="center"
                  key={`grid-${index}`}
                >
                  <CartItem
                    item={obj}
                    canEdit={true}
                    key={`list-${index}-${cartUpdated}`}
                    onUpdate={(cartItem: DCartItem) => {
                      DWZStore.addToCart(cartItem).then((cart) => {
                        //obj.quantity = newTotalQuantity;
                        setCart(cart);
                        setCartUpdated(new Date().getTime());
                      });
                    }}
                    onDelete={(cartItem: DCartItem) => {
                      DWZStore.removeFromCart(cartItem).then((cart) => {
                        setCart(cart);
                        setCartUpdated(new Date().getTime());
                      });
                    }}
                  />
                </Grid>
              );
            })}

            <Grid container item lg={12} justifyContent="center">
              <h3 className="center">Ready to check out? ...</h3>
            </Grid>

            <Grid
              container
              item
              sm={12}
              md={4}
              lg={3}
              justifyContent="flex-end"
              justifyItems="flex-end"
              justifySelf="flex-end"
              textAlign="center"
              alignItems="stretch"
            >
              {" "}
              <Button
                variant="outlined"
                size="small"
                sx={{"height": "100%", "width": "100%"}}
                color={totalItems == 0 ? "warning" : "warning"}
                onClick={() => {
                  router("/store");
                }}
              >
                <ArrowBack /> Continue Shopping
              </Button>
            </Grid>
            <Grid
              container
              item
              sm={12}
              md={4}
              lg={3}
              justifyContent="center"
              textAlign="center"
              alignItems="stretch"
            >
              <Button
                variant="contained"
                size="small"
                sx={{"height": "100%", "width": "100%"}}
                color="secondary"
                disabled={totalItems == 0}
                onClick={() => {
                  router("/store/checkout");
                }}
              >
                <Payment /> Check Out (${totalAmount.toFixed(2)})
              </Button>
            </Grid>

            <Grid
              container
              item
              sm={12}
              md={4}
              lg={3}
              justifyContent="flex-start"
              textAlign="center"
              alignItems="stretch"
            >
              <Button
                variant="outlined"
                size="small"
                sx={{"height": "100%", "width": "100%"}}
                color="info"
                disabled={totalItems == 0}
                onClick={() => {
                  DWZStore.clearCart().then((cart) => {
                    setCart(cart);
                  });
                }}
              >
                <DeleteOutline /> Clear My Bag 
              </Button>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <div id="cart">
          <Fade
            in={true}
            timeout={4000}
            //timeout={{ appear: 5000, enter: 3000, exit: 1000 }}
          >
            <div>
              <Zoom
                in={animate}
                style={{ transitionDelay: animate ? "500ms" : "0ms" }}
              >
                <div className="animation">
                  <CircularProgress color="warning" />
                </div>
              </Zoom>

              <Fab
                variant="extended"
                color="secondary"
                size="large"
                aria-label="Sign Up"
                style={{ margin: 0 }}
                onClick={() => {
                  //myRefSignup.current?.scrollIntoView();
                  router("/store/cart");
                }}
              >
                <ShoppingBasket style={{ fontSize: 30 }} /> {totalItems}
              </Fab>{" "}
              <br />
              <Zoom
                in={animate}
                style={{ transitionDelay: animate ? "100ms" : "0ms" }}
              >
                <LinearProgress color="warning" />
              </Zoom>
            </div>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default Cart;

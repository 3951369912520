/**
 * Markdown component to convert string with Markdown format.
 * @param props
 * @returns
 */

const MarkDownElement = (props: any) => {
  const re = props.re; // regular expression

  return <>Element</>;
};

const Markdown = (props: any) => {
  const string = props.string as string;

  let text: string = "###### Thsi is a test. ";
  // Match "world" using a regular expression

  // header
  const re = [
    {
      re: /^#{6}\s?([^\n]+)/gm,
    },
    {
      re: /^#{5}\s?([^\n]+)/gm,
    },
    {
      re: /^#{4}\s?([^\n]+)/gm,
    },
    {
      re: /^#{3}\s?([^\n]+)/gm,
    },
    {
      re: /^#{2}\s?([^\n]+)/gm,
    },
    {
      re: /^#{1}\s?([^\n]+)/gm,
    },
    // bold
    {
      re: /\*\*\s?([^\n]+)\*\*/g,
    },
    {
      re: /\_\_\s?([^\n]+)\_\_/g,
    },
    // italic
    {
      re: /\*\s?([^\n]+)\*/g,
    },
    {
      re: /\_\s?([^\n]+)\_/g,
    },
    // strikethrough
    {
      re: /\~\~\s?([^\n]+)\~\~/g,
    },
    // image
    {
      re: /\!\[([^\]]+)\]\((\S+)\)/g,
    },
    // link
    {
      re: /\[([^\n]+)\]\(([^\n]+)\)/g,
    },
    // paragraph
    {
      re: /([^\n]+\n?)/g,
    },
  ];

  const re1 = /^#{6}\s?([^\n]+)/;
  const re2 = /^#{5}\s?([^\n]+)/;
  const re3 = /^#{4}\s?([^\n]+)/;
  const re4 = /^#{3}\s?([^\n]+)/;
  const re5 = /^#{2}\s?([^\n]+)/;
  const re6 = /^#{1}\s?([^\n]+)/;

  // bold
  const re7 = /\*\*\s?([^\n]+)\*\*/;
  const re8 = /\_\_\s?([^\n]+)\_\_/;

  // italic
  const re9 = /\*\s?([^\n]+)\*/;
  const re10 = /\_\s?([^\n]+)\_/;

  // strikethrough
  const re11 = /\~\~\s?([^\n]+)\~\~/;

  // image
  const re12 = /\!\[([^\]]+)\]\((\S+)\)/;

  // link
  //const re13 = /\[([^\n]+)\]\(([^\n]+)\)/g;
  //const re13 = new RegExp(/\[([^n])\]\((https:\/\/[^\n]+)\)/, "g");
  const re13 = /\[([^\n]+)\]\(([^\n\s]+)\)/g;
  // paragraph
  const re14 = /([^\n]+\n?)/g;

  // header
  const matches1: RegExpMatchArray | null = string.match(re1);
  const matches2: RegExpMatchArray | null = string.match(re2);
  const matches3: RegExpMatchArray | null = string.match(re3);
  const matches4: RegExpMatchArray | null = string.match(re4);
  const matches5: RegExpMatchArray | null = string.match(re5);
  const matches6: RegExpMatchArray | null = string.match(re6);

  // bold
  const matches7: RegExpMatchArray | null = string.match(re7);
  const matches8: RegExpMatchArray | null = string.match(re8);

  const matches9: RegExpMatchArray | null = string.match(re9);
  const matches10: RegExpMatchArray | null = string.match(re10);

  // image
  const matches12: RegExpMatchArray | null = string.match(re12);

  // link
  const matches13: RegExpMatchArray | null = string.match(re13);

  // paragraph
  const matches14: RegExpMatchArray | null = string.match(re14);


  console.log(matches13);

  //const multilines = string.split(/\r?\n/) as string[];

  return (
    <>



      {matches1 != null && <h6>{matches1[1]}</h6>}
      {matches2 != null && <h5>{matches2[1]}</h5>}
      {matches3 != null && <h4>{matches3[1]}</h4>}
      {matches4 != null && <h3>{matches4[1]}</h3>}
      {matches5 != null && <h2>{matches5[1]}</h2>}
      {matches6 != null && <h1>{matches6[1]}</h1>}

      {matches7 != null && <b>{matches7[1]}</b>}
      {matches8 != null && <b>{matches8[1]}</b>}

      {matches9 != null && <i>{matches9[1]}</i>}
      {matches10 != null && <i>{matches10[1]}</i>}

      {matches12 != null && <img src={matches12[2]} alt={matches12[1]} />}

      {matches13 != null && <a href={matches13[2]} target="_blank" rel="noopener">{matches13[1]}</a>}

    
      {matches1 == null &&
        matches2 == null &&
        matches3 == null &&
        matches4 == null &&
        matches5 == null && 
        matches6 == null && 
        matches7 == null && 
        matches8 == null && 
        matches9 == null && 
        matches10 == null && 
        matches12 == null && 
        matches13 == null && 
        (
          <div>
            <p>{string}</p>
          </div>
        )}
    </>
  );
};

export default Markdown;

import { useEffect, useRef, useState } from "react";

import "./style.css";
import { DCart } from "../model";
import { DWZStore } from "../api/dwz-store";
import Loading from "../../components/loading/loading";
import { Backdrop, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param props
 * @returns
 *
 *
 * To test a typical PayPal transaction, you must use both types of accounts. When you register as a PayPal developer on the developer site, the PayPal sandbox creates these sandbox accounts: A business account and associated API test credentials.
 *
 */

const PayUponDeliveryButton = (props: any) => {

  const navigate = useNavigate();

  const [cart, setCart] = useState(props.cart as DCart);
  const [totalAmount, setTotalAmount] = useState(
    DWZStore.countTotalAmount(cart)
  );

  const [paying, setPaying] = useState(false);
  const [paid, setPaid] = useState(false);
  const [message, setMessage] = useState("");

  const [toPay, setToPay] = useState(false); // set button status after user confirms the agreeement to pay upon delivery

  useEffect(() => {}, []);

  const submitOrder = (orderData: any) => {
    let order = {
      id: orderData.id, // use the paypal order id as BSG order id
      date: new Date().toISOString(),
      status: "NEW",
      cart: cart,
      pay_option: "pay_upon_delivery",
      pay_amount: totalAmount,
    };

    setPaying(true);
    setPaid(true);
    DWZStore.updateOrder(order).then((json) => {
      if (json.status == "ok") {
        setMessage(
          "Thank you for your order! We are working on it and notify your for delivery arranagement. "
        );

        let newCart = json.data as DCart; 
        navigate("/store/order-status", { state: newCart});
        
      } else {
        setMessage(
          "Thank you for your payment! Your order has been recorded! We will email you confirmation and instruction soon for next step!"
        );
      }

      // clear cart
      DWZStore.clearCart().then((cart) => {
        setCart(cart);
      });

      setPaying(false);
    });
  };

  return (
    <div id="smart-button-container">
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        })}
        open={paying}
        onClick={() => {}}
      >
        <Loading show={paying} />
      </Backdrop>

      <div>
        <div>
          <h3 className="center">{message}</h3>

          <Button variant="contained" onClick={()=> {
              //navigate("/store/order-status", { state: item});
          }} color="warning">Check Status</Button>
        </div>
        <div style={{ visibility: paying || paid ? "hidden" : "visible" }}>
          

  <FormControlLabel control={<Checkbox
            onChange={(e) => {
              setToPay(e.target.checked);
            }}
            />} label={`I agree to pay ${totalAmount.toFixed(2)} USD upon delivery.`} />
          <Button
            variant="contained"
            color="warning"
            disabled={!toPay}
            onClick={() => {
              submitOrder({ id: "BSG-" + new Date().getTime() });
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PayUponDeliveryButton;

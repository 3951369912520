import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./style.scss";
import { UserContext } from "../../context/user-context";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { Avatar, Box, Chip, Container, Grid, IconButton, TextField } from "@mui/material";
import { DCompany, DPerson, DRelationship } from "../../model/index";
import NodeCard from "./node-card";
import NodePicker from "./node-picker";
import { AdminAPI2 } from "../../auth/admin-api2";
import Loading from "../loading/loading";

const RelationshipCard = (props: any) => {
  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  const [relationship, setRelationship] = useState(props.relationship as DRelationship);

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  
  const updateRelationship = () => {

    setLoading(true);
    
    AdminAPI2.adminUpdateRelationship(
      context.user.cognitoCredentials,
      relationship
    ).then((json) => {
      //console.log(json);
      //setData(data);  
      if(json.status == 'ok') {
        //console.log("OK ..."); 
        //setObjects(json.data);  // for array type testing
        //setData(json.data); // for json type testing
        setLoading(false); 
      } else {
        //console.log("BAD ..." + json.status); 
        setError(json.status);
        setShowError(true); 
      }
    });
  };

  const deleteRelationship = () => {

    setLoading(true);
    
    AdminAPI2.adminDeleteRelationship(
      context.user.cognitoCredentials,
      relationship
    ).then((json) => {
      //console.log(json);
      //setData(data);  
      if(json.status == 'ok') {
        //console.log("OK ..."); 
        //setObjects(json.data);  // for array type testing
        //setData(json.data); // for json type testing
        setLoading(false); 
      } else {
        //console.log("BAD ..." + json.status); 
        setError(json.status);
        setShowError(true); 
      }
    });
  };


  return (
<Container maxWidth="lg" style={{"border": "1px solid silver"}}>
<Grid
            container
            spacing={8}
            rowSpacing={8}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >

            <Grid container item xs={12} lg={4} justifyContent="center">

              <NodeCard node={relationship.fromNode} onClick={()=>{}} onClickText="TBD"/>
      

            <NodePicker node={relationship.fromNode} onChange={(node: any)=> { setRelationship({...relationship, fromNode: node})}}/>
              
          </Grid>
          <Grid container item xs={12} lg={4} justifyContent="center">

          {loading ? (
            <div>
              <Loading show={loading} />
              
            </div>
          ) : (
            <div>
<h2>Relationship: 
  
  {relationship.fromNode?.value?.id} -- 
  {relationship.type } { JSON.stringify(relationship.value )}</h2>
 --
  {relationship.toNode?.value?.id}

<TextField
                    required
                    id="relationship-type"
                    color="secondary"
                    variant="outlined"
                    label="Relationship Type"
                    autoComplete="relationship type"
                    defaultValue={""}
                    value={relationship.type}
                    onKeyDown={ (e: any) => {
                      
                    }}
                    onChange={(e: any) => {
                      setRelationship({...relationship, type: e.target.value});
                    }}
                  />


<TextField
                    required
                    id="relationship-value-description"
                    color="secondary"
                    variant="outlined"
                    label="Relationship Description"
                    autoComplete="relationship value"
                    defaultValue={""}
                    value={relationship.value?.description as string}
                    onKeyDown={ (e: any) => {
                      
                    }}
                    onChange={(e: any) => {
                      setRelationship({...relationship, value: {description: e.target.value}});
                    }}
                  />

{showError && (
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                      setShowError(false);
                    }}
                    severity="error"
                  >
                    {error}
                  </MuiAlert>
                )}
</div>)}


<Button variant="outlined"  onClick={()=>{ deleteRelationship(); }}>Delete Relationship</Button>
            <Button variant="outlined"  onClick={()=>{ updateRelationship(); }}>Add Relationship</Button>

          </Grid>
          <Grid container item xs={12} lg={4} justifyContent="center">

          <NodeCard node={relationship.toNode}  onClick={()=>{ }} onClickText="TBD"/>

            <NodePicker node={relationship.toNode} onChange={(node: any)=> { setRelationship({...relationship, toNode: node})}}/>
           
          </Grid>
          </Grid>
</Container>
  ); 
}

export default RelationshipCard;

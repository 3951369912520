import React from "react";

import { useNavigate } from "react-router-dom";

import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import { Box, Hidden } from "@mui/material";
import QRCode from "../qrcode";

const ThemeCard = (props: React.PropsWithChildren<ICard>) => {
  const history = useNavigate();

  const routeTo = (route: string) => {
    history(route);
  };

  return (
    <div
      className="theme-card"
      style={{
        minHeight: `${props.minHeight}px`,
        height: "100%",
      }}
    >
      <div className="theme-card-background">
        <div
          style={
            props.backgroundImg
              ? {
                  position: "relative",
                  backgroundImage: `url(${props.backgroundImg})`,
                  backgroundPosition: "center top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "auto",
                  height: "auto",
                  minHeight: `${props.minHeight}px`,
                  opacity: props.opacity ? props.opacity : 1,
                }
              : {}
          }
        ></div>
        <div className="theme-card-background-buffer"></div>
      </div>

        <Box  sx={{ display: { lg: 'flex', md: 'none', sm: 'none', xs: 'none' } }}>
        <div className="theme-card-caption">{props.caption}</div>
        </Box>
      <div className="theme-card-content">
        <div>
          {props.image && (
            <CardMedia
              component="img"
              alt={props.title}
              image={props.image}
              title={props.title}
            />
          )}
          <Hidden smUp>
            <span className="center">
              <QRCode text={window.location.href} />
            </span>
          </Hidden>
          <h1 className="primary-light center">{props.title}</h1>
          <div>{props.children}</div>
        </div>
        <div className="action-botton">
          {props.route ? (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                routeTo(props.route as string);
              }}
            >
              Go
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ThemeCard;

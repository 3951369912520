"use client";

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
  Hidden,

} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { useNavigate } from "react-router-dom";
import { DCart, DCartItem, DProduct } from "../model";
import {
  DeleteOutline,
  ExpandMore,
  Handshake,
  ShoppingBasket,
  ThumbUp,
  TouchAppRounded,
} from "@mui/icons-material";
import { DWZStore } from "../api/dwz-store";
import { deepOrange, green, purple } from "@mui/material/colors";
import ProductCard from "./product-card";
import CartItem from "./cart-item";

/**
 * This is Cart component view (different from Cart page view)
 * CartSummary - implement summary view for a Cart (with list of cart items and total # of items and total $price)
 * @param props
 * @returns
 */
const CartSummary = (props: any) => {
  const router = useNavigate();

  const [cart, setCart] = useState(props.cart as DCart);
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [expanded, setExpanded] = useState(props.expanded);

  useEffect(() => {
    var totalItems = DWZStore.countTotalItems(cart);
    var totalAmount = DWZStore.countTotalAmount(cart);
    setTotalItems(totalItems);
    setTotalAmount(totalAmount);
  }, [cart]);

  return (
    <Accordion style={{ width: "100%" }} expanded={expanded} onChange={(e: any)=>{ setExpanded(!expanded); }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box
          sx={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
            justifySelf: "center",
            alignSelf: "center",
            display: "flex",
          }}
        >
          <Avatar sx={{ bgcolor: purple[100], marginRight: "10px" }}>
            <ShoppingBasket
              style={{
                fontSize: 36,
              }}
              color="secondary"
            />
          </Avatar>
              <h3>
          Order Summary{" "}
          {`(Total Items: ${totalItems}, Total: $${totalAmount.toFixed(2)})`}
          </h3>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{textAlign: "center", border: "0px solid red", padding: "20px 10px 20px 10px", width: "100%"}}>

        <Grid
          container
          direction="row"
          columnSpacing={4}
          rowSpacing={8}
          justifyContent="center"
          justifyItems="center"
          alignItems="stretch"
          textAlign="center"
          alignContent="center"
        >
          {cart != null ? (
            <>
              {cart.items?.map((obj: DCartItem, index: number) => {
                return (
                  <Grid
                  container
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    direction="row"
                    columnSpacing={2}
                    rowSpacing={2}
                    rowGap={2}
                    alignItems="stretch"
                    justifyContent="center"
                    textAlign="center"
                    key={`grid-${index}`}
                  >
                    <CartItem
                      item={obj}
                      canEdit={false}
                      key={`list-${index}`}
                      onUpdate={(cartItem: DCartItem) => {}}
                      onDelete={(cartItem: DCartItem) => {}}
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            ""
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <h3 className="center">{`(Total Items: ${totalItems}, Total: $${totalAmount.toFixed(2)})`}</h3>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CartSummary;

import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { DCompany, DContent } from "../../model";
import { useNavigate } from "react-router-dom";

const BusinessCard = (props: any) => {


  const navigate = useNavigate();
  
  const [content, setContent] = useState(props.business as DCompany);

  return (
    <Card className="business-card">
      {content.logo ? <img src={`${content.logo}`} alt="" style={{"height": "auto", "maxWidth": "300"}}/> : ""}
      <CardContent>
        <div className="title">{content.name}</div>
        <hr></hr>
        <div className="text">{content.description?.substring(0, 50)} ...</div>
      </CardContent>

      {content.web ? (
              <a href={content.web} target="_blank">
                <Button variant="outlined" color="secondary">Web</Button>
              </a>
            ) : (
              ""
            )}

    </Card>
  );
};

export default BusinessCard;

import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import Banner from "../components/banner/banner";
import { Button, Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DCompany, DPerson } from "../model";
import { ContextService } from "../context/context-service";

import { BSGAPI } from "../auth/bsg-api";
import MemberCard from "../components/card/member-card";
import Loading from "../components/loading/loading";
import { BSGAPI2 } from "../auth/bsg-api2";
import CompanyCard from "../components/card/company-card";
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
export interface Props {
  text: string;
  /**
     * 
    ok: boolean;
    id?: number;  // ? optional
    fn: (bob: string) => string;
    person: Person;
     */
}

const AcademyCommunity = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  const [keyword, setKeyword] = useState(""); 
  const [searchType, setSearchType] = useState('p' as ('p' | 'c' )); // p - people, c - company

  const [records, setRecords] = useState([] as DPerson[]);

  const submitSearch = () => {

    if(searchType == 'p') {
      setLoading(true);
      BSGAPI2.searchBSGPersonsByKeyword(
        context.user.cognitoCredentials,
        keyword
      ).then((json) => {
        //console.log(json);
        //setData(data);  
        if(json.status == 'ok') {
          //console.log("OK ..."); 
          //setObjects(json.data);  // for array type testing
          //setData(json.data); // for json type testing
          setRecords(json.data as DPerson[]); 
          setLoading(false); 
        } else {
          //console.log("BAD ..." + json.status); 
        }
      });
    } else if(searchType == 'c') {
      setLoading(true);
      BSGAPI2.searchBSGCompaniesByKeyword(
        context.user.cognitoCredentials,
        keyword
      ).then((json) => {
        //console.log(json);
        //setData(data);  
        if(json.status == 'ok') {
          //console.log("OK ..."); 
          //setObjects(json.data);  // for array type testing
          //setData(json.data); // for json type testing
          //console.log("JSON: " + JSON.stringify(json.data)); 
          setRecords(json.data as DCompany[]); 
          setLoading(false); 
        } else {
          //console.log("BAD ..." + json.status); 
        }
      });
    } 
  };

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        forceUpdate();
        setLoading(false); 
        /** 
        BSGAPI2.getBSGPersons(
          context.user.cognitoCredentials, 
          0
        ).then((json) => {
          //console.log("JSON: " + json.status); 
          setRecords(json.data as DPerson[]);
          setLoading(false);
        });
        **/ 
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/bsg-community`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        
        history("/sign-in");

      }
    });

    document.title = `${ContextService.SITE_NAME}:My BSG Community`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>My Community: BSG Academy</h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h2>Connect with with your peers in our community</h2>
          </Grid>
        </Grid>
      </Banner>


      <div className="section-white">
        <Container maxWidth="lg">


      <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="keyword"
                    color="secondary"
                    variant="outlined"
                    label="Searcy by keyword"
                    autoComplete="keyword"
                    defaultValue={""}
                    value={keyword}
                    onKeyDown={ (e: any) => {
                      if(e.key === 'Enter') {
                        e.preventDefault();
                        submitSearch();
                      }
                    }}
                    onChange={(e) => {
                      //setUser({ ...user, username: e.target.value });
                      setKeyword(e.target.value); 
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="p" control={<Radio />} label={<><PeopleIcon color="secondary" /> People</>} 
          checked={ searchType == 'p'}
          onChange={(e:any)=>{
            setSearchType(e.target.value); 
            setRecords([]); 
            //setKeyword(""); 
        }} />


      
        <FormControlLabel value="c" control={<Radio />} label={<div><BusinessIcon color="warning" /> Company</div>}
          checked={ searchType == 'c'}
            onChange={(e:any)=>{
              setSearchType(e.target.value); 
              setRecords([]); 
              //setKeyword(""); 
        }} />

      </RadioGroup>
    </FormControl>
                  
                </Grid>

                <Grid
                  container
                  item
                  xs={6}
                  alignContent="flex-end"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      submitSearch(); 
                    }}
                  >
                    Searh
                  </Button>
                </Grid>
              </Grid>
              </Container>
</div>




      <div className="section-white">
        <Container maxWidth="lg">
          {loading ? (
            <div>
              <Loading show={loading} />
            </div>
          ) : (
            <Grid
              container
              spacing={6}
              justifyContent="center"
              alignItems="stretch"
            >
              {records?.map((record, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    justifyContent="center"
                  >
                    { searchType == 'p' ? 
                    <MemberCard
                      member={record}
                      key={`record-${index}`}
                      onClickText="view"
                      onClick={()=> { history(`/bsg-network/${record["id"]}`)}}
                    ></MemberCard>:""
              }

{ searchType == 'c' ? 
                    <CompanyCard
                      company={record}
                      key={`record-${index}`}
                      onClickText="view"
                      onClick={()=> { history("/person/")}}
                    ></CompanyCard>:""
              }
                  </Grid>
                );
              })}
            </Grid>

          )}


      { records.length == 0?  
              <Grid item xs={12}>
              <h3 className="center warning-dark">
                No records found. 
              </h3>
            </Grid>: ""

            }
        </Container>
      </div>
    </div>
  );
};

export default AcademyCommunity;

import { ContextService } from "../context/context-service";
import {
  DCognitoCredentials,
} from "../model";


export class PublicAPI {
 
    static API = [
      {
        name: "states",
        endpoint: "https://my-bsg-json.s3.amazonaws.com/lookup/state.json",
      },
      {
        name: "program-active",
        endpoint: "https://my-bsg-json.s3.amazonaws.com/program-active.json",
      },
      {
        name: "business-bsg",
        endpoint: "https://my-bsg-json.s3.amazonaws.com/business-bsg.json",
      },
      {
        name: "case-study-bsg",
        endpoint:
          "https://my-bsg-json.s3.amazonaws.com/projects-case-study.json",
      },
      {
        name: "jobs-bsg",
        endpoint: "https://my-bsg-json.s3.amazonaws.com/jobs-bsg.json",
      },
      {
        name: "job-application",
        endpoint: "/api/pub/my-bsg-job-application",
      },
      {
        name: "events-bsg",
        endpoint: "https://my-bsg-json.s3.amazonaws.com/events-bsg.json",
      },
      {
        name: "event-registration",
        endpoint: "/api/pub/my-bsg-event-registration",
      },
      {
        name: "industries",
        endpoint: "/api/pub2/industries",
      },
      {
        name: "online-inquiry",
        endpoint: "/api/pub/my-bsg-online-inquiry",
      },
      {
        name: "oauth-linkedin-profile",
        endpoint: "/api/pub/my-bsg-oauth-linkedin-profile",
      },
      {
        name: "oauth-linkedin-email",
        endpoint: "/api/pub/my-bsg-oauth-linkedin-email",
      },
      {
        name: "cognito-verify",
        endpoint: "/api/pub/cognito-verify",
      },
      {
        name: "oauth-cognito-profile",
        endpoint: "/api/pub/my-bsg-oauth-cognito-profile",
      },
      {
        name: "my-person",
        endpoint: "/api/my/person",
      },
      {
        name: "my-profile",
        endpoint: "/api/my/profile",
      },
      {
        name: "my-profile-update",
        endpoint: "/api/my/profile-update",
      },
      {
        name: "my-payments",
        endpoint: "/api/my/payments",
      },
      {
        name: "my-candidates",
        endpoint: "/api/my/candidates",
      },
      {
        name: "my-candidate",
        endpoint: "/api/my/candidate",
      },
    ]; 

  constructor() {}

  static config() {
    //console.log("This is config of BSG API class");
  }


  // for a API name return endpoint (url)
  static getEndpoint(name: string) {
    var api = this.API.find((x) => x.name == name);
    if(api != null) {
      return api.endpoint; 
    }
  }

  // HTTP API Get
  static get(
    api: string, 
    params?: {}
  ) {
    return new Promise<any>((resolve, reject) => {

      var headers = {
          //"Cache-Control": "No-Cache",
          "Cache-Control": `${ContextService.CACHE_MAX_AGE}`,
          "Content-Type": "application/json",
          Authorization: "", // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate      },
        }; 
      const options = {
        method: "GET",
        headers: headers,
        queryStringParameters: params,
      };
      var url = this.getEndpoint(api) as string;
      //console.log("URL: " + url); 
      fetch(url, options)
        .then((response) => response.json())
        .then((json) => {
          //console.log("JSON: " + JSON.stringify(json)); 
          //console.log(json.json());
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static post(
    api: string, 
    json?: {}
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          //"Cache-Control": "No-Cache",
          "Cache-Control": `${ContextService.CACHE_MAX_AGE}`,
          "Content-Type": "application/json",
          Authorization: ""
        },
        body: JSON.stringify(json),
      };
      var url = this.getEndpoint(api) as string;
      fetch(url, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

}

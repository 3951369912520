import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { DContent } from "../../model";
import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import Multiline from "./multiline";

const NewsCard = (props: any) => {
  const [content, setContent] = useState(props.content as DContent);

  return (

    <div className="news-card">
<Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            item
            xs={12}
            md={4}
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
            alignContent="flex-start"
          >
{content.image ? <img src={`${content.image}`} alt="" style={{maxWidth: "300px", width: "auto", marginTop: "10px", marginLeft: "4px", borderRadius:  
              "10px"}}/> : ""}
          </Grid>


          <Grid
            item
            xs={12}
            md={8}
            justifyContent="flex-start"
            alignItems="flex-start"
          >

<div className="content left">
        <div className="title">{content.title}</div>
        <div className="text"><Multiline string={content.text} /></div>
        <p></p>
        {content.link ? (
          <div>
          <Link target="_blank" to={content.link}><LaunchIcon /> {content.link}</Link>
          </div>

      ) : (
        ""
      )}
      </div>
          </Grid>
          </Grid>
          </div>
  );
};

export default NewsCard;

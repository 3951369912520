"use client";

import { DCognitoCredentials } from "../../model";
import {
  DCart,
  DCartItem,
  DChoiceOption,
  DPriceOption,
  DProduct,
} from "../model";

export class DWZStoreAdmin {

  /**
   * get orders
   */
    static adminGetOrders(credentials: DCognitoCredentials, query: any) {
      return new Promise<any>((resolve, reject) => {
        const options = {
          method: "POST",
          headers: {
            "Cache-Control": "No-Cache",
            "Content-Type": "application/json",
            Authorization: `Bearer ${credentials.AccessToken}`,
          },
          body: JSON.stringify({
            query: query
          }),
        };
        fetch("/api2/admin-get-orders", options)
          .then((response) => response.json())
          .then((json) => {
            resolve(json); // let client to deal with error/exception
          })
          .catch((err) => {
            //console.log("Error: " + err);
            reject(err);
          });
      });
    }



}

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Container, Fade, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DUser, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import { BSGAPI } from "../auth/bsg-api";
import { BSGAPI2 } from "../auth/bsg-api2";
import MediaCard from "../components/card/media-card";

import Loading from "../components/loading/loading";
import ThemeCard from "../components/card/theme-card";
import useDynamicRef from "../hooks/dynamic-ref";
import useScrollTrigger from "../hooks/scroll-trigger";

const AcademyContent = (props: any) => {
  const { t, i18n } = useTranslation("content");
  
  const [getRef, setRef] = useDynamicRef();

  const getTrigger = useScrollTrigger();

  //const [triggerWe, setTriggerWe] = useState(
    //getTrigger(myRefWe.current).trigger
    //false
  //);

  //let triggers: boolean[] = []; 
  const [triggers, setTriggers] = useState([] as boolean[]);

  const history = useNavigate();

  const [webContent, setWebContent] = useState({} as DWebContent);

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const [content, setContent] = useState([] as any[]);

  let [loading, setLoading] = useState(true);


  const myRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    document.title = "BSG Partner:Content";

    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        forceUpdate();
        //getMyPayments();
        setLoading(true);
        BSGAPI2.getBSGContent(context.user.cognitoCredentials, "BSG").then((json) => {

          if(json.status == 'ok') {
            setContent(json.data as any[]);
          } else if (json.status == "token-expired") {
            //setTimeout(() => {
              history("/sign-in?flow=REFRESH_TOKEN_AUTH");
            //}, 3000);
          } else {
          }
          setLoading(false); 
        });
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/bsg-content`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });
    BSGAPI.getWebContent("bsg-content").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });


    //window.scrollTo(0, 0);
  }, []);

  const updateTrigger = (index: number, trigger: boolean) => {
    setTriggers( prevTriggers => {
      const newTriggers = [...prevTriggers];
      newTriggers[index] = trigger;
      return newTriggers;
    })

  }

  useEffect(() => {

    if(content == null) return;

    //console.log("triggering ...");
    //let newTriggers: boolean[] = [];
    //for(let i = 0; i < content.length; i++) {
    //  newTriggers.push(false);
    //}
    //setTriggers(newTriggers);

    window.onscroll = () => {
      for(let i = 0; i < content.length; i++) {
        
        if(triggers[i] != true && getTrigger(getRef(i.toString()).current as HTMLElement).trigger)
          updateTrigger(i, (getTrigger(getRef(i.toString()).current as HTMLElement).trigger));      
        //console.log("[" + i + "]: " + triggers[i]); 

      }
    };


    //getTrigger(getRef("0").current as HTMLElement).trigger.scrollIntoView();
    //window.scrollTo(0, 10);  // this is necessary to trigger onscroll method above. 

    myRef.current?.scrollIntoView();

  }, [content]);

  return (
    <div>
    <ThemeCard
      backgroundImg="https://my-bsg-asset.s3.amazonaws.com/image/theme_columbus.jpg"
      title=""
      description=""
      minHeight={500}
      opacity={0.6}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <div ref={myRef}></div>
            <h1 className="primary-light">{webContent.h1?.title} </h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2 className="primary-light">{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      </ThemeCard>

      <Container maxWidth="xl">
        <h4 className="center">&copy; BSG - The following materials are for BSG Academy internal use only. </h4>
        <h1></h1>
        
        {loading ? (
          <div>
            <Loading show={loading} />
          </div>
        ) : (
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            {content?.map((item, index) => {
              return (
                <Grid
                  key={`grid-${index}`}
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  justifyContent="center"
                >
                  <div ref={setRef(index.toString()) as React.RefObject<any>}></div>

                    { triggers[index] && 
  
                  <MediaCard media={item} key={`key-${index}`}></MediaCard>
                    }
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
      </div>
  );
};

export default AcademyContent;

import { useEffect, useRef, useState } from "react";

import "./style.css";
import { DCart } from "../model";
import { DWZStore } from "../api/dwz-store";
import Loading from "../../components/loading/loading";
import { Backdrop } from "@mui/material";
import Multiline from "../../components/card/multiline";
import Markdown from "../../components/card/markdown";

declare var paypal: any;
/**
 *
 * @param props
 * @returns
 *
 *
 * To test a typical PayPal transaction, you must use both types of accounts. When you register as a PayPal developer on the developer site, the PayPal sandbox creates these sandbox accounts: A business account and associated API test credentials.
 *
 */

const PaypalButton = (props: any) => {
  const PAYPAL_IS_SANDBOX = process.env.NODE_ENV == "development";
  const PAYPAL_SERVER = PAYPAL_IS_SANDBOX
    ? "https://sandbox.paypal.com"
    : "https://www.paypal.com";
  const PAYPAL_CLIENT_ID: string = PAYPAL_IS_SANDBOX
    ? "AQkN3hkdVYc3okmLpDaTARVOX-XReNjmWirbqtWWRDMi7Gpxsoark6DM0XvWhqGSFuZ_3VOL0l8r7UE1"
    : "AeazJ03v8GArfd9FRiJ31FG9rSEDeHqI0WCgEmEn_bpcgwfDynIgu_tAK1W07H_7qyjZ9uUWLKqF7dOk";

  const [cart, setCart] = useState(props.cart as DCart);
  const [totalAmount, setTotalAmount] = useState(
    DWZStore.countTotalAmount(cart)
  );

  const [paying, setPaying] = useState(false);
  const [paid, setPaid] = useState(false);
  const [message, setMessage] = useState(""); 

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      //"https://www.paypal.com/sdk/js?client-id=AYkRvzCIZzrz5ssA8CGNybMbEOKbP4XSVLzCA2y-JsxYaEa1YrJlvY2cOAZdOnXE6-onAhLfmyGNuDvR&enable-funding=venmo&currency=USD";
      `${PAYPAL_SERVER}/sdk/js?client-id=${PAYPAL_CLIENT_ID}&enable-funding=venmo&currency=USD`;
    script.async = false;
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      //console.log("initializing paypal button ...");
      initPayPalButton();
    });
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initPayPalButton = () => {
    paypal
      .Buttons({
        style: {
          shape: "pill",
          color: "gold",
          layout: "vertical",
          label: `checkout`,
        },

        createOrder: function (data: any, actions: any) {
          
          return actions.order.create({
            purchase_units: [
              //{
              //  description: "BSG Career Coaching - 2021 (Test)",
              //  amount: { currency_code: "USD", value: 0.1 },
              //},
              {
                description: "BSG Online Store Order",
                amount: { currency_code: "USD", value: totalAmount },
              },
            ],
          });
        },

        onApprove: function (data: any, actions: any) {
          return actions.order.capture().then(function (orderData: any) {
            // Full available details
            //console.log(
            //  "Capture result",
            //  orderData,
            //  JSON.stringify(orderData, null, 2)
            //);

            // Show a success message within this page, e.g.
            let order = {
              id: orderData.id, // use the paypal order id as BSG order id
              date:  new Date().toISOString(),
              status: "NEW", 
              cart: cart, 
              pay_option: "paypal", 
              pay_amount: totalAmount,
              paypal_order_id: orderData.id,
              paypal_order_status: orderData.status,
              paypal_order_data: orderData,
            };
            
            setPaying(true); 
            setPaid(true); 
            DWZStore.updateOrder(order).then((json) => {

              if (json.status == "ok") {
                setMessage("Thank you for your payment! We will email you confirmation and instruction soon for next step!");
              } else {
                setMessage("Thank you for your payment! Your order has been recorded! We will email you confirmation and instruction soon for next step!");
              }

              // clear cart
              DWZStore.clearCart().then((cart) => {
                setCart(cart);
              });

              setPaying(false); 
            });
            // Or go to another URL:  actions.redirect('thank_you.html');
            // clear cart
            //DWZStore.clearCart().then((cart) => {
            //});
          });
        },

        onError: function (err: any) {
          //console.log(err);
          setPaid(false); 
          setMessage("Something went wrong. Please try again later! Details: " + err);
          setPaying(false);
        },
      })
      .render("#paypal-button-container");
  };

  return (
    <div id="smart-button-container">
            <Backdrop
                sx={(theme) => ({
                  color: "#fff",
                  zIndex: theme.zIndex.drawer + 1,
                })}
                open={paying}
                onClick={() => {
                }}
              >
        <Loading show={paying} />
              </Backdrop>
      
      <div>

       <div><h3 className="center">{ message }</h3></div>
        <div id="paypal-button-container" style={{visibility: (paying || paid) ?"hidden":"visible"}}></div>

        {PAYPAL_IS_SANDBOX && (
          <div>[You are in development mode - Paypal Sandbox]</div>
        )}
      </div>
    </div>
  );
};

export default PaypalButton;

/***
 * 
 * Raw code from Paypal button code generator
 * 

<div id="smart-button-container">
      <div style="text-align: center;">
        <div id="paypal-button-container"></div>
      </div>
    </div>
  <script src="https://www.paypal.com/sdk/js?client-id=AeazJ03v8GArfd9FRiJ31FG9rSEDeHqI0WCgEmEn_bpcgwfDynIgu_tAK1W07H_7qyjZ9uUWLKqF7dOk&enable-funding=venmo&currency=USD" data-sdk-integration-source="button-factory"></script>
  <script>
    function initPayPalButton() {
      paypal.Buttons({
        style: {
          shape: 'pill',
          color: 'gold',
          layout: 'vertical',
          label: 'checkout',
          
        },

        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{"description":"BSG Career Coaching - 2021","amount":{"currency_code":"USD","value":698}}]
          });
        },

        onApprove: function(data, actions) {
          return actions.order.capture().then(function(orderData) {
            
            // Full available details
            //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

            // Show a success message within this page, e.g.
            const element = document.getElementById('paypal-button-container');
            element.innerHTML = '';
            element.innerHTML = '<h3>Thank you for your payment!</h3>';

            // Or go to another URL:  actions.redirect('thank_you.html');
            
          });
        },

        onError: function(err) {
          //console.log(err);
        }
      }).render('#paypal-button-container');
    }
    initPayPalButton();
  </script>

 */

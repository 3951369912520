import "./App.scss";

//import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/nav/nav";
import Test from "./pages/test/Test";
import { UserContextProvider } from "./context/user-context";
import { ContextService } from "./context/context-service";
import Footer from "./components/footer/footer";

import { theme } from "./theme/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Services } from "./pages/services";
import Test2 from "./pages/test2/Test2";
import Post1 from "./posts/Post1";
import Post2 from "./posts/Post2";
import Test3 from "./pages/test3/Test3";
import CareerCoaching from "./pages/career-coaching";
import ComingSoon from "./pages/coming-soon";
import CookieConsent from "./components/consent/cookie-consent";
import Jobs from "./pages/jobs";
import JobApply from "./pages/jobs/job-apply";
import TrainingEnrollment from "./pages/bsg-academy/training-enrollment";
import React, { useEffect, useState } from "react";
import SignIn from "./my/sign-in";
import Training from "./pages/training";
import SignOut from "./my/sign-out";
import Contact from "./pages/contact";
import CaseStudy from "./pages/use-study";
import Events from "./pages/events";

import SignUp from "./my/sign-up";
import ChangePassword from "./my/change-password";
import MyHome from "./my/my-home";
import ForgotPassword from "./my/forgot-password";
import MyPayment from "./my/my-payment";
import MyDocument from "./my/my-document";
import MyCandidates from "./my/my-candidates";
import Candidate from "./my/my-candidate";
import MyProfile from "./my/my-profile";
import MySchedule from "./my/my-schedule";
import MyReferral from "./my/my-referral";
import Promo from "./my/promo";
import LinkedInSignIn from "./user/linkedin-sign-in";
import Page from "./my/page";
import BSGSSOSignIn from "./my/bsg-sso";
import MyCommunity from "./my/my-community";
import MyTest from "./my/my-test";
import Neo4j from "./pages/test/neo4j";
import Onboarding from "./admin/admin-onboarding"; 
import AdminHome from "./admin/admin-home";
import AdminUserManagement from "./admin/admin-user-management";
import AdminUserEdit from "./admin/admin-user-edit";
import AdminUserProfile from "./admin/admin-user-profile";
import GetStarted from "./pages/get-started";
import ILab from "./pages/ilab";
import AdminNetwork from "./admin/admin-network";
import AdminPersonEdit from "./admin/admin-person-edit";
import AdminCompanyEdit from "./admin/admin-company-edit";
import CommunityHome from "./community/community-home";
import AcademyHome from "./academy/academy-home";
import AcademyCommunity from "./academy/academy-community";
import AcademyNetwork from "./academy/academy-network";
import AcademyContent from "./academy/academy-content";
import EventSignup from "./community/community-event";
import CommunityBusiness from "./community/community-business";
import AdminBusinessEdit from "./admin/admin-business-edit";
import Store from "./store/store";
import Cart from "./store/components/cart";
import Checkout from "./store/checkout";
import ViewCart from "./store/view-cart";
import Product from "./store/product";
import BuyNow from "./store/buy-now";
import Orders from "./store/admin/orders";
import OrderStatus from "./store/order-status";
import PartnerGetStarted from "./partner/get-started";

function App() {
  //const context = useContext(userContext);

  let [context, setContext] = useState(new ContextService());

  let [contextUpdate, setContextUpdate] = useState(false);

  const forceUpdate = () => {
    setContextUpdate(!contextUpdate);
  };

  useEffect(() => {
    //document.title = "BSG:Welcome";
    context.init().then(() => {
      //context.setUser(data.user as DUser);
      //context.setBusiness(data.business as DBusiness);
      //console.log("Init: " + JSON.stringify(context.user));
      //forceUpdate();
    });
  }, []);

  return (
    <UserContextProvider value={{ context, contextUpdate, forceUpdate }}>
      <ThemeProvider theme={theme}>

      <BrowserRouter>
          <div className="App">
            <Nav></Nav>
            <div className="App-main">
        <Routes>
              <Route />
              <Route path="/" element={ <MyHome />} />
              
              <Route path="/get-started" element={ <GetStarted />} />
              <Route path="/home" element={ <MyHome />} />
              <Route path="/intro" element={ <Promo/>} />
              <Route path="/page/:id" element={<Page/>} />
              <Route path="/page/:id/:lang" element={<Page/>} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/jobs/:id" element={<Jobs/> } />
              <Route path="/jobs/:id/:action" element={<Jobs/> } />
              <Route path="/job-apply" element={<JobApply/>} />
              <Route path="/job-apply/:id" element={<JobApply/>} />

              <Route path="/training"  element={<Training/>} />
              <Route path="/training-enrollment" element={<TrainingEnrollment/>} />
              <Route path="/training-enrollment/:id" element={<TrainingEnrollment/>} />
              <Route path="/ilab" element={<ILab/>} />
              <Route path="/case-study" element={<CaseStudy/>} />
              <Route path="/case-study/:category" element={<CaseStudy/>} />
              <Route path="/contact-us" element={<Contact/>} />
              <Route path="/career-coaching" element={<CareerCoaching/>} />
              <Route path="/profile" element={<MyProfile/>} />
              <Route path="/pay" element={<MyPayment/>} />
              <Route path="/referral" element={<MyReferral/>} />
              <Route path="/candidates" element={<MyCandidates/>} />
              
              <Route path="/my-test" element={<MyTest/>} />
              <Route path="/candidates/:companyId" element={<MyCandidates />}/>
              <Route path="/candidate/:companyId/:id" element={<Candidate />} />
              <Route path="/documents" element={<MyDocument/>} />
              <Route path="/schedule" element={<MySchedule/>} />


              <Route path="/linkedin-sign-in" element={<LinkedInSignIn />} />
              <Route path="/sso-sign-in" element={<BSGSSOSignIn/>} />
              <Route path="/sign-in" element={<SignIn/>} />
              <Route path="/sign-up" element={<SignUp/>} />
              <Route path="/sign-up/:step" element={<SignUp/>} />
              <Route path="/sign-out" element={<SignOut/>} />
              <Route path="/change-password" element={<ChangePassword/>} />
              <Route path="/forgot-password" element={<ForgotPassword/>} />
              <Route path="/forgot-password/:step" element={<ForgotPassword />} />
              <Route path="/events" element={<Events/>} />
              <Route path="/events/:id" element={<Events/>} />
              <Route path="/events/:id/:action" element={<Events/>} />
              <Route path="/test" element={<Test/>} />
              <Route path="/test2" element={<Test2/>} />
              <Route path="/test3" element={<Test3/>} />
              <Route path="/services" element={<Services/>} />
              <Route path="/post1" element={<Post1/>} />
              <Route path="/post2" element={<Post2/>} />
              <Route path="/coming-soon" element={<ComingSoon/>} />

              <Route path="/test/neo4j" element={ <Neo4j />} />
              

              <Route path="/community" element={ <CommunityHome />} />
              <Route path="/business" element={ <CommunityBusiness />} />
              <Route path="/business/:id" element={ <CommunityBusiness />} />
              <Route path="/event-signup" element={ <EventSignup />} />
              <Route path="/event-signup/:g" element={ <EventSignup />} />
              
              <Route path="/academy" element={ <AcademyHome />} />
              <Route path="/community-old" element={<MyCommunity/>} />
              <Route path="/academy/community" element={<AcademyCommunity/>} />
              <Route path="/academy/network" element={<AcademyNetwork/>} />
              <Route path="/academy/network/:id" element={<AcademyNetwork/>} />
              <Route path="/academy/content" element={<AcademyContent/>} />

              <Route path="/admin" element={ <AdminHome />} />
              <Route path="/admin/user-management" element={ <AdminUserManagement />} />
              <Route path="/admin/user-profile/:email" element={ <AdminUserProfile />} />
              <Route path="/admin/user-edit/:email" element={ <AdminUserEdit />} />
              <Route path="/admin/person-edit" element={ <AdminPersonEdit />} />
              <Route path="/admin/person-edit/:email" element={ <AdminPersonEdit />} />
              <Route path="/admin/company-edit" element={ <AdminCompanyEdit />} />
              <Route path="/admin/company-edit/:id" element={ <AdminCompanyEdit />} />
              <Route path="/admin/business-edit" element={ <AdminBusinessEdit />} />
              <Route path="/admin/business-edit/:id" element={ <AdminBusinessEdit />} />
              <Route path="/admin/user-onboarding" element={ <Onboarding />} />
              <Route path="/admin/network" element={<AdminNetwork/>} />
              <Route path="/admin/network/:id" element={<AdminNetwork/>} />

              <Route path="/partner/get-started" element={ <PartnerGetStarted />} />

              <Route path="/store" element={ <Store />} />
              <Route path="/store/cart" element={ <ViewCart />} />
              <Route path="/store/checkout" element={ <Checkout />} />
              <Route path="/store/product/:id" element={<Product/>} />
              <Route path="/store/buy" element={<BuyNow/>} />
              <Route path="/store/order-status" element={<OrderStatus/>} />
              <Route path="/store/admin/orders" element={<Orders/>} />


              </Routes>


            </div>
            <Footer></Footer>
            <CookieConsent></CookieConsent>
          </div>

          </BrowserRouter>
      </ThemeProvider>
    </UserContextProvider>
  );
}

export default App;

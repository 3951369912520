import { ContextService } from "../context/context-service";
import {
  DCognitoCredentials,
  DCognitoUser,
  DEventSignup,
  DJobApplication,
  DLog,
  DLogItem,
  DNameValue,
  DOpportunity,
  DTrainingEnrollment,
} from "../model";

export default interface IJobAssessmentSubType {
  name?: string;
  value?: string;
  color?:
    | "inherit"
    | "disabled"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

export class BSGAPI {
  static WEB_CONTENT_ROOT =
    "https://my-bsg-json.s3.amazonaws.com/content/bsg-my";

    static MAX_UPLOAD_FILE_SIZE = 10 * 1000 * 1000; // bytes

    static RegExp_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static RegExp_PHONE =
    /(^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$){1}/;

  static RegExp_PHONE_ALLOWED_INPUT = /[\d-. \(\)]/;
  //static RegExp_PASSWORD = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/; // require at least 1 number and 1 special characters,
  static RegExp_PASSWORD = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;

  static RegExp_RECOVERY_CODE = /^[a-zA-Z0-9!@#$%^&*]{4,10}$/;

  static JOB_APPLICATION_TYPE = "HIRING";

  static JOB_APPLICATION_ASSESSMENT_SUBTYPES = [
    {
      name: "Strength",
      value: "Strength",
      color: "success",
    },
    {
      name: "Mild Strength",
      value: "Mild Strength",
      color: "success",
    },
    {
      name: "Mixed",
      value: "Mixed",
      color: "secondary",
    },
    {
      name: "Mild Concern",
      value: "Mild Concern",
      color: "warning",
    },
    {
      name: "Concern",
      value: "Concern",
      color: "error",
    },
    {
      name: "Not Specified",
      value: "",
      color: "info",
    },
  ] as IJobAssessmentSubType[];

  static JOB_APPLICATION_MILESTONES = [
    {
      code: "SCREEN",
      description: "Screening",
      values: ["Incline to hire", "Incline to not hire", "Not sure yet"],
    },
    {
      code: "INTERVIEW",
      description: "Interview",
      values: ["Incline to hire", "Incline to not hire", "Not sure yet"],
    },
  ];

  constructor() {}

  static config() {
    //console.log("This is config of BSG API class");
  }

  static submitTrainingEnrollment(application: DTrainingEnrollment) {
    return new Promise<any>((resolve, reject) => {
      //console.log("array Buffer: " + application.resume.arrayBuffer);
      //application.resume = Buffer.from(application.resume).toString("base64");
      const options = {
        method: "POST",
        headers: {
          //"Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: ``,
        },
        //body: JSON.stringify({ user: user, params: {} }),
        body: JSON.stringify({ data: application }),
      };
      fetch("/api/pub/submit-job-application", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

    static submitJobApplication(application: DJobApplication) {
      return new Promise<any>((resolve, reject) => {
        //console.log("array Buffer: " + application.resume.arrayBuffer);
        //application.resume = Buffer.from(application.resume).toString("base64");
        const options = {
          method: "POST",
          headers: {
            //"Cache-Control": "No-Cache",
            "Content-Type": "application/json",
            Authorization: ``,
          },
          //body: JSON.stringify({ user: user, params: {} }),
          body: JSON.stringify({ data: application }),
        };
        fetch("/api/pub/submit-job-application", options)
          .then((response) => response.json())
          .then((json) => {
            resolve(json); // let client to deal with error/exception
          })
          .catch((err) => {
            //console.log("Error: " + err);
            reject(err);
          });
      });
    }


  // submit event sign up request
  static submitEventSignup(application: DEventSignup) {
    return new Promise<any>((resolve, reject) => {
      //console.log("array Buffer: " + application.resume.arrayBuffer);
      //application.resume = Buffer.from(application.resume).toString("base64");
      const options = {
        method: "POST",
        headers: {
          //"Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: ``,
        },
        //body: JSON.stringify({ user: user, params: {} }),
        body: JSON.stringify({ data: application }),
      };
      fetch("/api/pub/submit-event-signup", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }
      
  // get community members
  static getCommunityMembers(
    credentials: DCognitoCredentials,
    user: DCognitoUser
  ) {
    return new Promise<any>((resolve, reject) => {
      const url =
        "https://script.google.com/macros/s/AKfycbzTcmds5YnqT9Lyrp36cq5kaWSTHx9jOPWLbdI7Xe5EcfT3_--GLqHHnwPKraxiI8c8/exec";
      const options = {
        //redirect: "follow",
        method: "GET",
        "Cache-Control": "No-Cache",
        "Content-Type": "application/json",
      };
      fetch(url, options)
        .then((response) => response.json())
        .then((json) => {
          //console.log(JSON.stringify(json));
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // get my profile (s3)
  static getProfile(credentials: DCognitoCredentials, user: DCognitoUser) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/profile", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // get my company
  static getCompanies(credentials: DCognitoCredentials, user: DCognitoUser) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/companies", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getClients(credentials: DCognitoCredentials, user: DCognitoUser) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/clients", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getCandidates(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    company: string | null | undefined
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: { company: company } }),
      };
      fetch("/api/my/candidates", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // candidate: person id of candidate
  static getCandidate(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    company: string,
    candidate: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { company: company, person: candidate },
        }),
      };
      fetch("/api/my/candidate", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // candidate: person id of candidate
  static getCandidateMilestones(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    company: string,
    person: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { company: company, person: person },
        }),
      };
      fetch("/api/my/candidate-milestones", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // candidate: person id of candidate
  static getCandidateLogs(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    company: string,
    candidate: string,
    subtype: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { company: company, person: candidate, subtype: subtype },
        }),
      };
      fetch("/api/my/candidate-logs", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // log: log object
  static addCandidateLog(
    candidate: string,
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    log: DLog
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { person: candidate, log: log },
        }),
      };
      fetch("/api/my/candidate-add-log", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // log: log object
  static updateCandidateLog(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    log: DLog
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: { log: log } }),
      };
      fetch("/api/my/candidate-update-log", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // log: log object
  static deleteCandidateLog(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    log: DLog
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: { log: log } }),
      };
      fetch("/api/my/candidate-delete-log", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // log item
  // candidate: person id of candidate
  static getCandidateLogItems(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    candidate: string,
    log: number // log id
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { person: candidate, log: log },
        }),
      };
      fetch("/api/my/candidate-log-items", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // log: log object
  static addCandidateLogItem(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    candidate: string,
    logItem: DLogItem
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { person: candidate, logItem: logItem },
        }),
      };
      fetch("/api/my/candidate-add-log-item", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // log: log object
  static updateCandidateLogItem(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    candidate: string,
    logItem: DLogItem
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { person: candidate, logItem: logItem },
        }),
      };
      fetch("/api/my/candidate-update-log-item", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  // log: log object
  static deleteCandidateLogItem(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    logItem: DLogItem
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { logItem: logItem },
        }),
      };
      fetch("/api/my/candidate-delete-log-item", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          //console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getOpportunity(credentials: DCognitoCredentials, user: DCognitoUser) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/opportunity", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getOpportunitySummary(
    credentials: DCognitoCredentials,
    user: DCognitoUser
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/opportunity-summary", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static updateOpportunity(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    objects: DOpportunity[]
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          data: objects,
        }),
      };
      fetch("/api/my/opportunity-update", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getPayments(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    person: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: {
            person: person,
          },
        }),
      };
      fetch("/api/my/payments", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getPaymentSummary(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    person: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: {
            person: person,
          },
        }),
      };
      fetch("/api/my/payment-summary", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getUserAttributes(
    credentials: DCognitoCredentials,
    user: DCognitoUser
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, params: {} }),
      };
      fetch("/api/my/user-attribute", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static updateUserAttributes(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    attributes: DNameValue[]
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({ user: user, data: attributes }),
      };
      fetch("/api/my/user-attribute-update", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getPersonAttributes(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    person: string,
    type: string
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          params: { person: person, type: type },
        }),
      };
      fetch("/api/my/person-attributes", options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static updatePersonAttributes(
    credentials: DCognitoCredentials,
    user: DCognitoUser,
    person: string,
    type: string,
    attributes: DNameValue[]
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${credentials.AccessToken}`,
        },
        body: JSON.stringify({
          user: user,
          data: attributes,
          params: { person: person, type: type },
        }),
      };
      fetch("/api/my/person-attribute-update", options)
        .then((response) => response.json())
        .then((json) => {
          //if (json.status == "ok") {
          //  console.log("json: " + JSON.stringify(json));
          //  resolve(json.data); // { object: DOpportunity[] }
          //} else {
          //  reject();
          //}
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getWebContent(contentKey: string) {
    return BSGAPI.getWebContentByLanguage(contentKey, null);
  }

  static getWebContentByLanguage(
    contentKey: string,
    language: string | null | undefined
  ) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          //"Cache-Control": "No-Cache",
          "Cache-Control": `${ContextService.CACHE_MAX_AGE}`,
          Authorization: "", // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate      },
        },
        queryStringParameters: {
          //person: context.user.person?.id,
        },
      };
      let url =
        language != undefined && language != null && language != ""
          ? `${this.WEB_CONTENT_ROOT}/${contentKey}_${language}.json`
          : `${this.WEB_CONTENT_ROOT}/${contentKey}.json`;
      fetch(url, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // format a database string format
  static formatDateString(dateStr: string | undefined) {
    if (dateStr == null || dateStr == undefined) dateStr = "(n/a)";
    let splits = dateStr.split("T");
    if (splits.length > 0) return splits[0];
    else return "(n/a)"; // not standard string, just pass an empty string.
  }

  // a generic data format validation method
  static validate(
    regEx: RegExp,
    name: string,
    value: string | undefined | null,
    help: string | undefined | null
  ) {
    if (value == null || value == undefined)
      return { isValid: true, err: `${name} cannot be empty` };
    let isValid = regEx.test(value.toLowerCase());
    let err = isValid ? "" : help ? help : `please provide a valid ${name}`;
    return { isValid: isValid, err: err };
  }

  // mask/transform a string to a masked value
  // mask: +1 DDD DDD DDDD
  // string： 828888888
  //static RegExp_PHONE_ALLOWED_INPUT = /^\+?[0-9\+ .-]/;
  static mask(mask: string, value: string): { value: string; cursor: number } {
    //let digits = value.substr(2).replace(/\D/g, ""); // start after +1
    let digits = value.replace(/\D/g, ""); // start after +1
    let masked = "";
    let index = 0;
    let cursor = 0;
    for (let i = 0; i < mask.length; i++) {
      if (mask.charAt(i) === "D") {
        if (index < digits.length) {
          masked = masked.concat(digits.charAt(index));
          index++;
          cursor = i; // track where the cursor is.
        } else {
          masked = masked.concat(mask.charAt(i));
        }
      } else {
        masked = masked.concat(mask.charAt(i));
      }
    }
    return { value: masked, cursor: cursor };
  }

  static format(str: string) {
    let regex = /\d/g;
    let digits = str.match(regex) as any; 
    if(digits == null) {
       digits = []; 
    }
    let mask = "(DDD) DDD DDDD"; 
    let masked = "";
    let j = 0;
    for(let i=0; i < mask.length; i++) {
      if(mask.charAt(i) === "D") {
        if(j < digits.length) {
          masked = masked.concat(digits[j]); 
          j++;
        } else {

        }
      } else {
        if(j < digits.length)
          masked = masked.concat(mask.charAt(i)); 
      }
    }
    return masked; 
  }

  static textToHtml(text: string) {
    return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }
}

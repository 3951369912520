import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Chip, Container, Fab, Fade, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DCompany,
  DPerson,
  DProfile,
  DUser,
  DUserAttribute,
  DWebContent,
} from "../model";
import { ContextService } from "../context/context-service";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import IconCard from "../components/card/icon-card";
import { BSGAPI } from "../auth/bsg-api";
import CompanyCard from "../components/card/company-card";
import { BSGAPI2 } from "../auth/bsg-api2";
import Loading from "../components/loading/loading";
import MemberCard from "../components/card/member-card";
import PersonCard from "../components/card/person-card";
import UserCard from "../components/card/user-card";
import AdminUserCard from "../components/card/admin-user-card";
import { AdminAPI2 } from "../auth/admin-api2";

const AdminUserManagement = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const [members, setMembers] = useState([] as DProfile[]);
  let [loading, setLoading] = useState(true);


  const [paymentTotal, setPaymentTotal] = useState(0.0);
  const [opportunityTotal, setOpportunityTotal] = useState(0);
  const [userAttribute, setUserAttribute] = useState({} as DUserAttribute);
  const [companies, setCompanies] = useState([] as DCompany[]);
  const [clients, setClients] = useState([] as DCompany[]);

  const [users, setUsers] = useState([] as DProfile[]);

  useEffect(() => {
    //document.title = "BSG Partner:Home";

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

        // get profiles
        AdminAPI2.adminGetProfiles(
          context.user.cognitoCredentials
        ).then((json) => {
          //console.log("Json: " + JSON.stringify(json));
          setMembers(json.data as DProfile[]);

          setLoading(false);
        }).catch((e: any) => {
          //console.log("Error: " + e);
        });

      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    BSGAPI.getWebContent("admin").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>



      <div className="section-white">
        <Container maxWidth="lg">
          {loading ? (
            <div>
              <Loading show={loading} />
            </div>
          ) : (
            <Grid
              container
              spacing={6}
              justifyContent="center"
              alignItems="stretch"
            >
              {members?.map((member, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    justifyContent="center"
                  >
                    <AdminUserCard
                      user={member}
                      key={`user-${index}`}
                      onClick={()=> {
                        history(`/admin/user-profile/${member.email}`)
                      }}
                      onClickText="View/Edit"
                      route={`/admin/user-edit/${member.email}`}
                      routeText="Quik Edit"
                    >
                    </AdminUserCard>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Container>
      </div>

    </div>
  );
};

export default AdminUserManagement;

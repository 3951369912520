import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/user-context";
import "./style.scss";
import { Button, Container, Grid, Paper } from "@mui/material";
import Markdown from "../../components/card/markdown";

export interface Props {
  text: string;
  /**
     * 
    ok: boolean;
    id?: number;  // ? optional
    fn: (bob: string) => string;
    person: Person;
     */
}

export default function Test2() {
  const { t } = useTranslation("content");

  const { context } = useContext(UserContext);

  //const str = 'test ' + "\n\r" +  "test 2" + "\n\r"; 
  //const str = 'test'; 
  //const str = '## This is test' + "\n"; 
  //const str = '**This is test**' + "\n"; 
  //const str = '*This is test*' + "\n"; 
  //const str = 'Test ![image](https://s3.amazonaws.com/dwz-data/assets/products/sku_2234.jpg)'; 
  const str = '[link](https://www.google.com) test again [Map](https://maps.google.com/)'; 
  //const str = '![image](https://s3.amazonaws.com/dwz-data/assets/products/sku_2234.jpg)' + "\n" + '[link](https://www.google.com)'; 
  //const str = '[link](https://www.google.com) Test [link map](https://maps.google.com) '; 
  
  let re = /(http[^\n\s]+)/g; 
  let myStr = '';

  let found = str.match(re);
  //console.log(found);

  return (
    <Container maxWidth="md">

      <div>

      <Markdown string={str} />

      </div>
      <h3>My name is: {context.user.language} </h3>
      <h1>{t("welcome.title")}</h1>
      <div className="navHeader">
        <div>This is a test</div>
        <Button>Test Button Style</Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="paper">xs=12</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className="paper">xs=12 sm=6</Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className="paper">xs=12 sm=6</Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className="paper">xs=6 sm=3</Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className="paper">xs=6 sm=3</Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className="paper">xs=6 sm=3</Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className="paper">xs=6 sm=3</Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

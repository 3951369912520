import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Badge, Box, Container, Grid } from "@mui/material";
import { DCart, DCartItem, DProduct } from "../model";
import {
  DeleteOutline,
  ExpandMore,
  Handshake,
  ShoppingBasket,
  ThumbUp,
  TouchAppRounded,
} from "@mui/icons-material";

interface ICartItemOption {
  item: DCartItem;
  onUpdate?: any;
  onDelete?: any;
  canEdit?: boolean;
}

/**
 * This is Cart component view (different from Cart page view)
 * CartSummary - implement summary view for a Cart (with list of cart items and total # of items and total $price)
 * @param props
 * @returns
 */
const CartItem = (props: React.PropsWithChildren<ICartItemOption>) => {
  var [cartItem, setCartItem] = useState(props.item as DCartItem);

  const onUpdate = props.onUpdate;
  const onDelete = props.onDelete;
  const canEdit = props.canEdit;

  useEffect(() => {}, [cartItem]);

  return (

    <Card className="product-card">
    <Box>

        <div className="image-responsive">
        
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            color="secondary"
            badgeContent={cartItem.quantity}
          ><img
                alt=""
                src={cartItem.product.image}
                style={{ borderRadius: 10 }}
              />
          </Badge>
        </div>
        </Box>

      <Box>
          <div>
            <span className="product-name"> {`${cartItem.product.name}`}</span>{" "}
            <span className="product-quantity">
              {" "}
              {`(x ${cartItem.quantity})`}
            </span>
          </div>
        </Box>
        <Box>
          <div className="product-sku">{`Sku-${cartItem.product.id}`}</div>
        </Box>
        <Box>
          <div>
            {cartItem.price_option.option != "" ? (
              <>{`${cartItem.price_option.option}`}</>
            ) : (
              ""
            )}
          </div>
        </Box>

        {cartItem.choice_options.length > 0 ? (
          <Box>
            <div className="product-choice">
              {`${cartItem.choice_options?.map((choice) => {
                return "[" + choice.name + ":" + choice.value + "]";
              })}`}
              )
            </div>
          </Box>
        ) : (
          ""
        )}

        <Box>
          <div>
            <span className="product-quantity">{`Quantity: ${cartItem.quantity} x `}</span>{" "}
            <span className="promo-price">{`$${cartItem.price_option.price}`}</span>{" "}
            {`${cartItem.product.unit}`}
          </div>
        </Box>

<Box flexGrow={1}></Box>
        {canEdit ? (
          <Box>

<Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
<Grid
              container
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              justifyContent="flex-end"
              justifyItems="flex-end"
              justifySelf="flex-end"
              textAlign="center"
              alignItems="stretch"
            >
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                //props.onClick();
                //onBuyNow();
                //                     var newTotalQuantity = obj.quantity + 1;
                //cartItem.quantity += 1; // change
                var myItem = cartItem;
                myItem.quantity = 1;
                setCartItem({ ...cartItem, quantity: cartItem.quantity + 1 });

                //console.log("Item: " + JSON.stringify(obj));
                //DWZStore.addToCart(obj).then((cart) => {
                //  setCart(cart);
                //  setCartUpdated(new Date().getTime());
                //});
                onUpdate(cartItem);
              }}
              style={{ padding: "0px", width: "9px" }}
            >
              +
            </Button>
            </Grid>
            <Grid
              container
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              justifyContent="flex-end"
              justifyItems="flex-end"
              justifySelf="flex-end"
              textAlign="center"
              alignItems="stretch"
            >
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                //props.onClick();
                //onBuyNow();
                //                      var newTotalQuantity = obj.quantity - 1;

                var myItem = cartItem;
                myItem.quantity = -1;
                setCartItem({ ...cartItem, quantity: cartItem.quantity - 1 });
                onUpdate(myItem);
              }}
            >
              -
            </Button>
            </Grid>
            <Grid
              container
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              justifyContent="flex-end"
              justifyItems="flex-end"
              justifySelf="flex-end"
              textAlign="center"
              alignItems="stretch"
            >
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                onDelete(cartItem);
              }}
            >
              <DeleteOutline />
            </Button>
            </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )}
      </Card>
  );
};

export default CartItem;

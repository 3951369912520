import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.css";
import ICard from "../../components/card/icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Box,
  Hidden,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { useNavigate } from "react-router-dom";
import { DCartItem, DChoiceOption, DChoiceOptions, DProduct } from "../model";

interface IProductCardOption {
  product: DProduct;
  onAddToCart?: any;
  onBuyNow?: any;
  showRetailPrice?: boolean;
  showPriceOptions?: boolean;
  showChoiceOptions?: boolean;
  showQuantityOptions?: boolean;
  showAddToCart?: boolean;
  showBuyNow?: boolean;
}

const ProductCard = (props: React.PropsWithChildren<IProductCardOption>) => {
  const [product, setProduct] = useState(props.product as DProduct);

  const onAddToCart = props.onAddToCart;
  const onBuyNow = props.onBuyNow;

  const navigate = useNavigate();
  const routeTo = (route: string) => {
    navigate(route);
  };

  const getDefaultChoiceOptions = (allChoiceOptions: DChoiceOptions[]) => {
    var choiceOptions = [] as DChoiceOption[];
    for (
      var i = 0;
      allChoiceOptions != null && i < allChoiceOptions.length;
      i++
    ) {
      choiceOptions.push({
        name: allChoiceOptions[i].name,
        value: allChoiceOptions[i].values[0],
      });
    }
    return choiceOptions as DChoiceOption[];
  };

  const [cartItem, setCartItem] = useState({
    product: product,
    quantity: product.min_quantity,
    price_option: { option: "", price: product.price_options[0].price },
    choice_options: getDefaultChoiceOptions(product.choice_options),
  } as DCartItem);

  const updateChoiceOptions = (choiceIndex: number, valueIndex: number) => {
    var choiceOptions = cartItem.choice_options;
    choiceOptions[choiceIndex].value =
      product.choice_options[choiceIndex].values[valueIndex];
    setCartItem({ ...cartItem, choice_options: choiceOptions });
  };

  return (
    <Card className="product-card">
      <Box>
        <div className="image-responsive">
          <img src={product.image} alt="" style={{borderRadius: 10}}/>
        </div>
      </Box>
      <Box>
        <span className="center product-name">{product.name}</span>{" "}
        <span className="product-sku">
          [SKU#
          {product.id}]
        </span>
      </Box>

      <Box flexGrow={1}>
        <span className="center product-description">
          {product.description}
        </span>
      </Box>

      <Box flexGrow={1}>
        <span className="center">Unit: {product.unit}</span>
      </Box>

      {props.showRetailPrice ? (
        <Box flexGrow={1}>
          <span className="center retail-price">
            Retail Price: {`$${product.list_price.toFixed(2)}`}
          </span>
        </Box>
      ) : (
        ""
      )}

      {props.showPriceOptions ? (
        <Box flexGrow={1}>
          <span className="center">
            {product.price_options.length > 1 ? (
              <span className="promo-price">
                Discount Price:{" "}
                <NativeSelect
                  className="promo-price"
                  key={`price-option-${product.id}`}
                  defaultValue={0}
                  onChange={(e: any) => {
                    //console.log("onChange Select: " + e.target.value);
                    var selectedIndex = e.target.value;
                    setCartItem({
                      ...cartItem,
                      price_option: product.price_options[selectedIndex],
                    });
                  }}
                >
                  {product.price_options.map((priceOption, index) => {
                    return (
                      <option
                        value={index}
                        key={`price-value-${product.id}-${index}`}
                        className="promo-price"
                      >
                        {`$${priceOption.price.toFixed(2)}`} (
                        {priceOption.option})
                      </option>
                    );
                  })}
                </NativeSelect>
              </span>
            ) : (
              <span className="promo-price">
                Discount Price:{" "}
                {`$${product.price_options[0].price.toFixed(2)}`}
              </span>
            )}
          </span>
        </Box>
      ) : (
        ""
      )}

      <Box flexGrow={1}>
        <span className="center product-choice">
          {product.choice_options.map((choice, index) => {
            return (
              <div key={`div-choice-${product.id}-${index}`}>
                Select {choice.name}:{" "}
                <NativeSelect
                  className="product-choice"
                  key={`choice-option-${product.id}-${index}`}
                  defaultValue={0}
                  onChange={(e: any) => {
                    //console.log("onChange Select: " + e.target.value);
                    var selectedValueIndex = e.target.value;
                    updateChoiceOptions(index, selectedValueIndex);
                  }}
                >
                  {choice.values.map((value, valueIndex) => {
                    return (
                      <option
                        value={valueIndex}
                        key={`choice-value-${product.id}-${valueIndex}`}
                      >
                        {value}
                      </option>
                    );
                  })}
                </NativeSelect>
              </div>
            );
          })}
        </span>
      </Box>

      {props.showQuantityOptions ? (
        <Box flexGrow={1}>
          <div className="center product-quantity">
            Quantity:{" "}
            <NativeSelect
              className="product-quantity"
              key={`quantity-choice-${product.id}`}
              defaultValue={product.min_quantity}
              onChange={(e: any) => {
                //console.log("onChange Select: " + e.target.value);
                var selectedQuantity = (1 * e.target.value) as number;
                setCartItem({ ...cartItem, quantity: selectedQuantity });
              }}
            >
              {Array(product.max_quantity - product.min_quantity + 1)
                .fill(1)
                .map((one, i) => {
                  return (
                    <option
                      value={i + product.min_quantity}
                      key={`quantity-value-${product.id}-${i}`}
                    >
                      {i + product.min_quantity}
                    </option>
                  );
                })}
            </NativeSelect>
          </div>
        </Box>
      ) : (
        ""
      )}

      <Box alignItems="flex-end">
        <CardActions>
          <div className="action-button">
            {props.showAddToCart ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  onAddToCart(cartItem);
                }}
              >
                Add to Cart
              </Button>
            ) : (
              ""
            )}

            {props.showBuyNow ? (
              <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => {
                  //props.onClick();
                  onBuyNow(cartItem);
                }}
              >
                Buy Now
              </Button>
            ) : (
              ""
            )}
          </div>
        </CardActions>
      </Box>
    </Card>
  );
};

export default ProductCard;

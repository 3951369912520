import {
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  Paper,
  Slide,
  TextField,
  useScrollTrigger,
} from "@mui/material";
import React, { useContext, useState } from "react";
//import { useTranslation } from "react-i18next";
//import { UserContext } from "../../context/user-context";
import { Buffer } from 'buffer';
import { useNavigate } from "react-router";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import "./style.scss";
//import { Link } from "react-router-dom";
import { DInquiry, DJobApplication } from "../../model";
import { BSGAPI } from "../../auth/bsg-api";

export default function JobApplicationForm(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  //const { t, i18n } = useTranslation("content");
  //const context = useContext(UserContext);
  const history = useNavigate();
  const jobId = props.jobId as string;

  const [uploadError, setUploadError] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");

  const [inquiry, setInquiry] = useState<DJobApplication>({
    firstName: "",
    lastName: "",
    jobId: jobId,
    phone: "",
    email: "",
    message: "",
    resume: null,
    resumeFileName: "",
    resumeFileType: "",
  });

  const [inquiryResponse, setInquiryResponse] = useState<any>({
    success: false,
    message: "",
  });

  function validate() {
    return (
      uploadError ||
      inquiry.resume == null ||
      inquiry.firstName!.length < 1 ||
      inquiry.lastName!.length < 1 ||
      inquiry.email!.length < 2 ||
      inquiry.phone!.length < 7
    );
  }

  function callAPI() {
    BSGAPI.submitJobApplication(inquiry).then((response2: any) => {
      if (response2.status == "ok") {
        setInquiryResponse({
          success: true,
          message: "Your application has been submitted!",
        });
      } else {
        setInquiryResponse({
          success: false,
          message:
            "Sorry. Your application is not successfully submitted. Please try again later. ",
        });
      }
    });

    //setInquiryResponse(data);
    //setInquiry({});
  }

  function onUploadFileChange(e: any) {
    let myFile: File = e.target.files[0];
    //console.log("file name ==> " + myFile.name);
    let fileParts = myFile.name.split(".");
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    let myReader = new FileReader();
    myReader.onload = ((file: File): any => {
      return (event: any) => {
        //console.log("onLoaded: completed");
        var binaryString = event.target.result;
        var base64 = Buffer.from(binaryString, "binary").toString("base64");
        if (base64.length > BSGAPI.MAX_UPLOAD_FILE_SIZE) {
          setUploadError(true);
          setUploadMessage("File is too big ...");
        } else if (
          fileType.toLowerCase() != "pdf" &&
          fileType.toLowerCase().indexOf("doc") != 0
        ) {
          setUploadError(true);
          setUploadMessage("Only PDF/Doc document type is accepted");
        } else {
          setUploadError(false);
          setUploadMessage(
            "resume is ready to be submitted with your application"
          );
          setInquiry({
            ...inquiry,
            resume: base64,
            resumeFileName: fileName,
            resumeFileType: fileType,
          });
          //callAPI();
        }
      };
    })(myFile);
    myReader.readAsArrayBuffer(myFile);
    //myReader.readAsBinaryString(myFile); // deprecated. 
  }

  return (
    <Container maxWidth="md">
      <div className="form">
        <h1>Job Application</h1>

        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {jobId ? (
            <h3>
              You are applying for this job:{" "}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  history(`/jobs/${jobId}`);
                }}
              >
                {jobId}
              </Button>
              <h4 className="primary-dark">
                Note:{" "}
                <i>
                  If you are qualified for other job opportunities we are
                  hiring, you will be automatically considered.
                </i>
              </h4>
            </h3>
          ) : (
            <h3>You are submitting your job application.</h3>
          )}
        </Grid>
        {!inquiryResponse.success ? (
          <Grid
            container
            columnSpacing={1}
            rowSpacing={1}
            alignContent="stretch"
          >
            <Grid container item xs={12} columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="first-name"
                  label="First Name"
                  value={inquiry.firstName}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, firstName: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="last-name"
                  label="Last Name"
                  value={inquiry.lastName}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, lastName: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} columnSpacing={1} rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="email"
                  label="Your E-mail"
                  value={inquiry.email}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, email: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  color="secondary"
                  id="phone"
                  label="Your Phone #"
                  value={inquiry.phone}
                  onChange={(e) => {
                    setInquiry({ ...inquiry, phone: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <TextField
                variant="outlined"
                color="secondary"
                id="note"
                label="Anything you want us to know - Please keep it brief"
                multiline
                maxRows={10}
                rows={3}
                value={inquiry.message}
                onChange={(e) => {
                  setInquiry({ ...inquiry, message: e.target.value });
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="secondary" component="label">
                <CloudUploadIcon style={{ fontSize: 40, marginRight: 6 }} />{" "}
                Choose Your Resume to Upload
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      //console.log("file name: " + e.target.files[0].name);
                      onUploadFileChange(e);
                      //setInquiry({ ...inquiry, resume: e.target.files[0] });
                    }
                  }}
                />
              </Button>
              {uploadError ? (
                <h3 className="warning-dark">
                  <b>Failed:</b> {uploadMessage}
                </h3>
              ) : (
                uploadMessage != "" && (
                  <h4 className="success-dark">
                    <b>Looks good: </b>
                    {`${inquiry.resumeFileName}.${inquiry.resumeFileType}: ${uploadMessage}`}
                  </h4>
                )
              )}
            </Grid>

            <Grid item xs={12}>
              * Your information will only be collected for BSG job application
              and communication purpose.
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="warning"
                onClick={callAPI}
                disabled={validate()}
              >
                Submit
              </Button>
              <h3 className="warning-dark">{inquiryResponse.message}</h3>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <h4 className="primary-dark">
                BSG is committed to providing rewarding and flexible career
                development opportunities and welcomes talents with different
                background and experience.
              </h4>
              <h4 className="primary-dark">
                BSG is an Equal Opportunity Employer.
              </h4>
              <h4 className="primary-dark">
                BSG does not discriminate on the basis of race, religion, color,
                sex, gender identity, sexual orientation, age, non-disqualifying
                physical or mental disability, national origin, veteran status
                or any other basis covered by appropriate law. All employment is
                decided on the basis of qualifications, merit, and business
                need.
              </h4>
            </Grid>
          </Grid>
        ) : (
          <div>
            <h2>Thank you!</h2>
            <h3>You may close this browser window now. </h3>
          </div>
        )}
      </div>
    </Container>
  );
}

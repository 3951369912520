import {  useEffect, useRef, useState } from "react";

import useScrollTrigger from "../../hooks/scroll-trigger";
import { Container, Fade, Grid } from "@mui/material";
import ThemeCard from "../../components/card/theme-card";
import "../../custom.d.ts";
import "./style.scss";
import Banner from "../../components/banner/banner";
import ContactForm from "../../components/form/contact-form";

const Contact = (props: any) => {

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    document.title = "BSG:Contact";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>


      <Fade in={triggerWe} timeout={4000}>

        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >

<Container maxWidth="md">
                <h1>Contact Us</h1>
                <h3>
                  Please provide a little info about your needs, we will get
                  back to you shortly.
                </h3>
                </Container>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
              </Grid>
            </Grid>
          </Banner>
        </div>

      </Fade>

      <div className="center">
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;

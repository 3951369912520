import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { DContent } from "../../model";
import BTextField from "./text-field";
import BMediaPicker from "./media-picker";

export interface IInputField {
  label?: string;
  tip?: string;
  editMode?: boolean;
  canEdit?: boolean; 
  name?: string;
  value?: string | number;
  multiline?: boolean;
  onChange?: any;
  onCommit?: any;
}

const BFormInput = (
  {
    label,
    tip,
    editMode,
    canEdit, 
    name,
    value,
    multiline,
    onChange,
    onCommit,
  }: IInputField
) => {


  const history = useNavigate();
  const routeTo = (route: string) => {
    history(route);
  };

  switch (name) {
    case "icon":
      return (
      <BMediaPicker
        label={label}
        tip={tip}
        editMode={editMode}
        canEdit={canEdit}
        name={name}
        value={ value}
        onChange={onChange}
      onCommit={onCommit}></BMediaPicker>);
    case "logo":
        return (
        <BMediaPicker
          label={label}
          tip={tip}
          editMode={editMode}
          canEdit={canEdit}
          name={name}
          value={ value}
          onChange={onChange}
        onCommit={onCommit}></BMediaPicker>);
    case "google-slide":
      return (
        <></>
      );
    default:
      return (
      <BTextField
      label={label}
      tip={tip}
      editMode={editMode}
      canEdit={canEdit}
      name={name}
      value={ value}
      onChange={onChange}
      onCommit={onCommit}
      ></BTextField>);
  }
};

export default BFormInput;

import { Card, Container, Grid } from "@mui/material";
import styles from "./layout.module.css";
import PaypalButton from "./components/paypal-button";
import { useEffect, useState } from "react";
import { DWZStore } from "./api/dwz-store";
import CartSummary from "./components/cart-summary";
import { DCart, DOrder } from "./model";
import { useLocation } from "react-router-dom";
import OrderSummary from "./components/order-summary";


export default function OrderStatus(props: any) {

  const location = useLocation();
  const order = location.state as DOrder;
  
  //const [cart, setCart] = useState(props.cart as DCart);

  useEffect(() => {
  }, []);

  return (
    <Container maxWidth="md">
      <div className={styles.center}>
        <h1>{ DWZStore.STORE_NAME} - Order Status</h1>
      </div>

      <Card>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          spacing={2}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            {order != null ? <OrderSummary order={order} expanded={true}/> : ""}
          </Grid>


        </Grid>
      </Card>
    </Container>
  );
}

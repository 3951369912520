import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Box, Button, Container, Fade, Grid, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import NameValueCard from "../components/card/name-value-card";
import { BSGAPI } from "../auth/bsg-api";
import { BSGAPI2 } from "../auth/bsg-api2";
import BFormInput from "../components/form/form-input";
import Loading from "../components/loading/loading";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { AdminAPI2 } from "../auth/admin-api2";

const AdminCompanyEdit = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useNavigate();

  const { id } = useParams();

  const [webContent, setWebContent] = useState({} as DWebContent);
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  //let [profile, setProfile] = useState({} as DProfile);
  const [object, setObject] = useState({
    name: "",
    symbol: "",
    industry: "",
    logo: "",
    score: 0,
    web: "",
    description: "",
    city: "",
    state: "",
    country: "",
  } as any);

  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");

  const [contactCardDisabled, setContactCardDisabled] = useState(true);

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const submitUpdateObject = () => {
    setLoading(true);
    AdminAPI2.adminUpdateCompany(context.user.cognitoCredentials, object).then(
      (json) => {
        //console.log(json);
        //setData(data);
        if (json.status == "ok") {
          //console.log("OK ...");
          //setObjects(json.data);  // for array type testing
          //setData(json.data); // for json type testing
          setLoading(false);
          setObject(json.data as any);
          setContactCardDisabled(true);
        } else {
          //console.log("BAD ..." + json.status);
          setError(json.status);
          setShowError(true);
        }
      }
    );
  };

  useEffect(() => {
    document.title = "BSG Partner:Edit Person";

    context.init().then(() => {
      if (context.user.cognitoUser!.Username) {
        // for testing:
        //getMyProfile();

        // just for testing:
        //getMyAttributes();

        //setGdrive(context.user.person!.gdrive as string);

        if (id != null) {
          setLoading(true);
          AdminAPI2.adminGetCompany(
            context.user.cognitoCredentials,
            id as string
          ).then((json) => {
            if (json.status == "ok") {
              //console.log("JSON: " + JSON.stringify(json));

              if (json.data != null) {
                setObject(json.data as any);
              }
              setLoading(false);
            } else {
              //console.log("Erro: " + JSON.stringify(json));
            }
          });

          forceUpdate();
        } else {
        }
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/admin/user-edit`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history("/sign-in");
      }
    });

    BSGAPI.getWebContent("profile").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div>
        {loading ? (
          <div>
            <Loading show={loading} />
          </div>
        ) : (
          <div className="section-white">
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignContent="center"
            >
              {showError && (
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={() => {
                    setShowError(false);
                  }}
                  severity="error"
                >
                  {error}
                </MuiAlert>
              )}
            </Grid>
            <Container maxWidth="lg">
              <Grid
                container
                rowSpacing={8}
                columnSpacing={2}
                justifyContent="center"
                alignItems="stretch"
                alignContent="center"
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <NameValueCard
                    key="info-contact"
                    icon={
                      <ManageAccountsIcon
                        color="info"
                        style={{ fontSize: 64 }}
                      />
                    }
                    title="Company Profile"
                    description=""
                    onClick={() => {
                      submitUpdateObject();
                      setContactCardDisabled(true);
                    }}
                    onClickText="Save"
                    onClickDisabled={contactCardDisabled}
                  >
                    {Object.keys(object).map((key: any) => {
                      return (
                        <BFormInput
                          label={key}
                          editMode={true}
                          name={key}
                          value={object[key]}
                          onChange={(value: any) => {
                            //console.log("onChange is called ...: " + key);
                            object[key] = value;
                            setObject({ ...object, [key]: value });

                            setContactCardDisabled(false);
                          }}
                        ></BFormInput>
                      );
                    })}

                    <Box
                      sx={{
                        flexGrow: 1,
                        justifyContent: "flex-end",
                        alignContent: "flex-end",
                        backgroundColor: "white",
                      }}
                    >
                      <TextField
                        required
                        id="keyword"
                        color="secondary"
                        variant="outlined"
                        label="new key"
                        autoComplete="keyword"
                        defaultValue={""}
                        value={newKey}
                        onKeyDown={(e: any) => {}}
                        onChange={(e) => {
                          setNewKey(e.target.value);
                        }}
                      />
                      <TextField
                        required
                        id="keyword"
                        color="secondary"
                        variant="outlined"
                        label="new value"
                        autoComplete="keyword"
                        defaultValue={""}
                        value={newValue}
                        onKeyDown={(e: any) => {}}
                        onChange={(e) => {
                          setNewValue(e.target.value);
                        }}
                      />{" "}
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setObject({ ...object, [newKey]: newValue });
                          setContactCardDisabled(false);
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                  </NameValueCard>
                </Grid>
              </Grid>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCompanyEdit;

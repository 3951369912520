import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Chip, Container, Fab, Fade, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  DCompany,
  DContent,
  DPerson,
  DUser,
  DUserAttribute,
  DWebContent,
} from "../model";
import { ContextService } from "../context/context-service";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import IconCard from "../components/card/icon-card";
import { BSGAPI } from "../auth/bsg-api";
import CompanyCard from "../components/card/company-card";
import { BSGAPI2 } from "../auth/bsg-api2";
import Loading from "../components/loading/loading";
import MemberCard from "../components/card/member-card";
import GroupsIcon from '@mui/icons-material/Groups';
import HubIcon from '@mui/icons-material/Hub';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContentCard from "../components/card/content-card";
import WebContentCard from "../components/card/web-content-card";
import Carousel from "../components/carousel/carousel";
import ScrollCard from "../components/card/scroll-card";
import NewsCard from "../components/card/news-card";
import BusinessCard from "../components/card/business-card";

const CommunityBusiness = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const navigate = useNavigate();

  const {id } = useParams(); 

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  let [loading, setLoading] = useState(true);

  //const [userAttribute, setUserAttribute] = useState({} as DUserAttribute);
  const [companies, setCompanies] = useState([] as DCompany[]);
  const [clients, setClients] = useState([] as DCompany[]);

  const [content, setContent] = useState({ items: [] } as DContent);

  const [page, setPage] = useState(null as any);

  const [sectionItems, setSectionItems] = useState([] as DContent[]);

  const [businessItems, setBusinessItems] = useState([] as DCompany[]);


  useEffect(() => {
    //document.title = "BSG Partner:Home";

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

        BSGAPI2.getBusiness(
          context.user.cognitoCredentials, 
          {}
        ).then((response: any) => {

          if(response.status == "ok") {
            //console.log("responseData: " + JSON.stringify(response.data));
            setBusinessItems(response.data);
            setLoading(false);
          } else if (response.status == "token-expired") {
            navigate("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
          }
        })

        // get company (as Client manager)
        /**
        BSGAPI.getCompanies(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((response: any) => {
          if (response.status == "ok") {
            setCompanies(response.data as DCompany[]);
          } else if (response.status == "token-expired") {
            navigate("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
          }
        });
         */


      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        navigate("/sign-in");
      }
    });

    BSGAPI.getWebContent("community").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });

    BSGAPI.getWebContent("community-news").then((json) => {
      let content = json as DContent;
      setContent(content as DContent);
      if(content.children) {
      if (content.children.length > 0) {
          // main page content (page title, etc.)
          const page = content.children.find((c) => c.key == "page");
          if (page) {
            setPage(page);
          }
          // body content section items:
          const items = content.children.filter((c) => c.key == "section");
          if (items != null) { 
            setSectionItems(items);
            //setBusinessItems(items);
          }
        }
      }

      setLoading(false);
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title} </h1>

          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>


      <div className="section-white">
        <Container maxWidth="md">
Recommended Business and Services go here. 
      
{loading ? (
      <div>
          <Loading /></div>
        ) : (
<Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
{businessItems.map((item: any, index: number) => {
            return (
                <BusinessCard business={item} key={`section-${index}`} />
            );
        })}

      </Grid>
        ) }
      
        </Container>

</div>
      </div>
  );
};

export default CommunityBusiness;
